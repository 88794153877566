import React, { useCallback, useState } from "react";
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import BlueCircle from '../../images/svg/blue-circle.svg';
import ClarityPictureLine from '../../images/svg/clarity_picture-line.svg';
import FluentFullScreen from '../../images/svg/fluent_full-screen.svg';
import Mobile from '../../images/svg/mobile.svg';
import TickCircleWhite from '../../images/svg/tick-circle-white.svg';
import TickCircleWhite2 from '../../images/svg/tick-circle-white2.svg';
import TickCircle from '../../images/svg/tick-circle.svg';
import { Faq } from '../fq';

const faq = [
  {
    q: "Where will my listing appear when promoted",
    a: "Your listing will appear at the first position in relevant search results. It will be bigger and more pictures will be visible in the gallery preview (see wireframe above)."
  },
  {
    q: "Why do I need to promote a single listing",
    a: "Your listing will appear at the first position in relevant search results. It will be bigger and more pictures will be visible in the gallery preview (see wireframe above)."
  },
  {
    q: "What if other agencies promote their listings in my location",
    a: "Your listing will appear at the first position in relevant search results. It will be bigger and more pictures will be visible in the gallery preview (see wireframe above)."
  },
  {
    q: "Is my listing eligible to promote?",
    a: "Your listing will appear at the first position in relevant search results. It will be bigger and more pictures will be visible in the gallery preview (see wireframe above)."
  },
  {
    q: "Can I promote several listings at the same time?",
    a: "Your listing will appear at the first position in relevant search results. It will be bigger and more pictures will be visible in the gallery preview (see wireframe above)."
  },
  {
    q: "Can I change promoted listings",
    a: "Your listing will appear at the first position in relevant search results. It will be bigger and more pictures will be visible in the gallery preview (see wireframe above)."
  },
  {
    q: "How can I cancel my subscription",
    a: "Your listing will appear at the first position in relevant search results. It will be bigger and more pictures will be visible in the gallery preview (see wireframe above)."
  }
]

const propmotionPlans = [
  {
    id: 1,
    title: "Intro",
    duration: "7 days",
    price: "100",
    intro: [
      {
        text: "Up to 10x more views",
        checked: true
      },
      {
        text: "First Page in search results",
        checked: true
      },

      {
        text: "Bigger listing and picture size with gallery",
        checked: false
      },

      {
        text: "First position in search result",
        checked: false
      },

      {
        text: "SEO optamization",
        checked: false
      },

      {
        text: "Own promote analytics platform",
        checked: false
      },
      {
        text: "Chat support",
        checked: false
      }
    ]
  },
  {
    id: 2,
    title: "Popular",
    duration: "15 days",
    price: "120",
    popular: [
      {
        text: "Up to 10x more views",
        checked: true
      },
      {
        text: "First Page in search results",
        checked: true
      },

      {
        text: "Bigger listing and picture size with gallery",
        checked: true
      },

      {
        text: "First position in search result",
        checked: true
      },

      {
        text: "SEO optamization",
        checked: false
      },

      {
        text: "Own promote analytics platform",
        checked: false
      },
      {
        text: "Chat support",
        checked: false
      }
    ]
  },
  {
    id: 3,
    title: "Enterprise",
    duration: "30 days",
    price: "150",
    enterprise: [
      {
        text: "Up to 10x more views",
        checked: true
      },
      {
        text: "First Page in search results",
        checked: true
      },

      {
        text: "Bigger listing and picture size with gallery",
        checked: true
      },

      {
        text: "First position in search result",
        checked: true
      },

      {
        text: "SEO optamization",
        checked: true
      },

      {
        text: "Own promote analytics platform",
        checked: true
      },
      {
        text: "Chat support",
        checked: true
      }
    ]
  }
]


export const Promote = () => {
  const [selectPlan, setSelectPlan] = useState("intro");

  const handleSelectPlan = useCallback((value) => {
    setSelectPlan(value.toLowerCase())
  }, [])

  return (
    <div className="promotion-wrapper" >
      <div className="promote">
        <div className="header">
          <h1>Promote Listing</h1>
          <p>Put the property on top of the competition</p>
        </div>
        <div className="promote-wrapper">
          <div className="left">
            {propmotionPlans.map((item) => {
              return (
                item[selectPlan]?.map((test, _idx) => {
                  return (
                    <div className={test.checked ? "active" : ""} key={_idx}>
                      <h3>{test.text}</h3>
                      {test.checked ? <Isvg src={TickCircle} /> : <Isvg src={TickCircleWhite} />}
                    </div>
                  )
                })
              )
            })}
          </div>
          <div className="right">
            {propmotionPlans.map((item, _idx) => {
              return (
                <div className={selectPlan.toLocaleUpperCase() === item.title.toLocaleUpperCase() ? "active" : ""} key={_idx} onClick={() => handleSelectPlan(item.title)}>
                  <div>
                    {selectPlan === item.title ?
                      <Isvg src={TickCircleWhite2} />
                      :
                      <Isvg src={BlueCircle} />
                    }

                    <div>
                      <h2>{item.title}</h2>
                      <span>{item.duration}</span>
                    </div>
                  </div>
                  <h6>€ {item.price} <span>/ Month</span></h6>
                </div>

              )
            })}
          </div>
        </div>
      </div>

      <div className="look-wrapper">
        <h1>What it will look like?</h1>
        <div className="mockup">
          <h2>Mockup</h2>
        </div>
        <div className="desc">
          <div>
            <Isvg src={FluentFullScreen} />
            <h6>Largest listing size</h6>
            <p>Catch user’s attention with bigger
              listing size</p>
          </div>
          <div>
            <Isvg src={ClarityPictureLine} />
            <h6>More photo reviews</h6>
            <p>Generate interest with more
              property photos</p>
          </div>
          <div>
            <Isvg src={Mobile} />
            <h6>Desktop & mobile</h6>
            <p>Stand out in both desktop and
              mobile search results</p>
          </div>
        </div>
      </div>

      <div className="faq-wrapper">
        <div className="header">
          <h3>FAQs</h3>
          <h1>Frequently asked questions</h1>
          <h6>Have questions? We’re here to help</h6>
        </div>
        {faq.map((item, _idx) => {
          return (
            <Faq key={_idx} q={item.q} a={item.a} />
          )
        })}
        <h4>If you can’t find an answer to your question please <Link to="/home">contact us</Link> </h4>
      </div>
    </div>
  )
}