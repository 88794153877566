import moment from "moment";
import { useMemo } from "react";

export const useFilterConfig = () => useMemo(() => ({
    days: [
        { _id: 1, text: 'Last 7 days', value: 7 },
        { _id: 1, text: 'Last 14 days', value: 14 },
        { _id: 1, text: 'Last 21 days', value: 21 },
        { _id: 1, text: 'Last 30 days', value: 30 }
    ],
    weeks: [
        { _id: 1, text: 'Last Week', value: 7 },
        { _id: 1, text: 'Last 4 Weeks', value: 28 },
        { _id: 1, text: 'Last 8 Weeks', value: 56 }
    ],
    months: [
        { _id: 1, text: 'Last Month', value: moment().subtract(1, 'months').daysInMonth() },
        { _id: 1, text: 'Last 3 Months', value: Array.from({ length: 3 }, (_, i) => moment().subtract(i + 1, 'months').daysInMonth()).reduce((a, b) => a + b, 0), },
        { _id: 1, text: 'Last 6 Months', value: Array.from({ length: 6 }, (_, i) => moment().subtract(i + 1, 'months').daysInMonth()).reduce((a, b) => a + b, 0), }
    ],
    filterKeywords: [
        // { _id: 1, text: 'Day' },
        // { _id: 1, text: 'Week' },
        { _id: 1, text: 'Month' }
    ]
}), [])