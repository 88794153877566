import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import Isvg from 'react-inlinesvg';
import { useNavigate } from 'react-router-dom';
import { BasicInfo } from '../../components/AIListings/BasicInfo';
import { Details } from '../../components/AIListings/Details';
import { RadioButton } from '../../components/AIListings/RadioButton';
import { Select } from '../../components/select';
import { HOST_NAME } from '../../config';
import { DefaultLayout } from '../../containers/DefaultLayout';
import { useToken } from '../../hooks/auth/useToken';
import Ai from '../../images/ai.png';
import PointerArrow from '../../images/svg/drop-down-pointer.svg';
import Info from '../../images/svg/info.svg';
import { useSpinnerModal } from '../../modals/Spinner/Provider';
import {
    useDetailsConfig,
    useMeasurementConfig,
    usePropertyTypeConfig
} from './useFormConfig';

import { useMagicSubscriptions } from '../AccountSettings/Subscription/useSubscriptionConfig';

const TRIES = 10;
const TRIES_LS_KEY = 'tries';

export const AIListings = () => {
    const [token,] = useToken();
    const [user, setUser] = useState(null)
    const [data, setData] = useState({ propertyType: '', descriptionLength: 200, measurementMunitPropertyArea: 'sqm (m²)', measurementMunitLivingArea: 'sqm (m²)', writingCreativity: 'neutral', language: 'en' });
    const [result, setResult] = useState('');
    const [descLength, setDescLength] = useState(200);
    const [showErrors, setShowErrors] = useState(false);
    const [index, setIndex] = useState(0);
    const [text, setText] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const navigation = useNavigate();
    const { openModal, closeModal } = useSpinnerModal();
    const maigSubscriptions = useMagicSubscriptions();
    const [isSubscribed, setIsSubscribed] = useState(false)
    const [currentTries, setCurrentTries] = useState(0)

    useEffect(() => {
        if(token) {
            const config = {
              method: 'get',
              url: `${HOST_NAME}user`,
              headers: {
                Authorization: 'Bearer ' + token,
              },
            };
            axios(config)
              .then((res) => {
                if(res.data.subscriptions.find((sub)=> sub.product_id === maigSubscriptions[0].product_id)) {
                    setIsSubscribed(true)
                }
                setUser(res.data)
            })
              .catch((err) => console.log(err));
        }
      }, [maigSubscriptions, token]);

    const handleResult = useCallback((value) => {
        setResult(value);
    }, []);

    const handleData = useCallback((value) => {
        setData((prevState) => ({ ...prevState, ...value }))
    }, [])

    const handlePropertyType = useCallback((event) => {
        setData((prevState) => ({ ...prevState, propertyType: event.target.name }))
    }, [])

    const onChangeDetail = useCallback((event) => {
        setData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }))
    }, [])

    const handleDescLength = useCallback((value) => {
        setDescLength(value);
        setData((prevState) => ({ ...prevState, descriptionLength: value }))
    }, [])

    const handleWritingCreativity = useCallback((event) => {
        setData((prevState) => ({
            ...prevState, writingCreativity: event.target.name
        }))
    }, [])

    const onChangeSelect = useCallback((event) => {
        setData((prevState) => ({ ...prevState, [event.name]: event[event.name] }));
    }, []);

    const updateLocalStorage = useCallback((value) => {
        localStorage.setItem(TRIES_LS_KEY, value)
    }, [])

    const getValueFromLocalStorage = useCallback(() => localStorage.getItem(TRIES_LS_KEY), [])


    useEffect(() => {
        const interval = setInterval(() => {
            if (index < text.length) {
                setResult(result + text[index]);
                setIndex(index + 1);
            } else {
                clearInterval(interval);
            }
        }, 20);
        return () => clearInterval(interval);

    }, [index, result, text]);

    useEffect(() => {
        if (text.length === result.length && result.length !== 0) {
            setIsDisabled(false)
        }
    }, [result.length, text.length])


    useEffect(() => {
        if (!getValueFromLocalStorage()) {
            updateLocalStorage(0)
        }
        setCurrentTries(getValueFromLocalStorage());
    }, [getValueFromLocalStorage, updateLocalStorage])

    const handleSubmit = useCallback(async () => {
        let tries = 0

        if(!isSubscribed){
            tries = getValueFromLocalStorage()
            
            if (parseInt(tries) >= TRIES) {
                navigation('/account-settings');
            }
        }

        
        setIndex(0)
        setResult('')
        setText('')

        data.bathrooms ??= '0';
        data.bedrooms ??= '0';
        data.propertyArea ??= '0';
        data.livingArea ??= '0';
        data.parking ??= '0';

        setData(data);

        console.log(data)

        if (data.propertyType && data.bedrooms && data.bathrooms && data.livingArea && data.propertyArea && data.parking && data.descriptionLength && data.writingCreativity && data.language) {
            // //post req to  `ai/description_generation`
            setIsDisabled(true);
            openModal(true);
            await axios.post(`${HOST_NAME}ai/description_generation`, { headers: { 'Authorization': 'Bearer ' + token }, data: data })
                //set generated text from response here    
                .then(res => {
                    closeModal();
                    setText(res.data.data.generated_description);
                    if(!isSubscribed) {
                        updateLocalStorage(parseInt(tries) + 1)
                        setCurrentTries(parseInt(tries) + 1)
                    }
                })
                .catch(err => {
                    console.log(err);
                    alert("Token is expired or invalid");
                    if(!isSubscribed) {
                        updateLocalStorage(parseInt(tries) + 1);
                        setCurrentTries(parseInt(tries) + 1)
                    }
                })
                .finally(() => { closeModal() })
        } else {
            setShowErrors(true);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
    }, [closeModal, data, getValueFromLocalStorage, isSubscribed, navigation, openModal, token, updateLocalStorage])

    const propertyType = usePropertyTypeConfig(handlePropertyType);
    const details = useDetailsConfig(onChangeDetail);
    const measurementConfig = useMeasurementConfig(onChangeSelect);

    return (
        <DefaultLayout hideNavbar={!token}>
            <div className="all-listing-wrapper">
                <div className="header">
                    <img src={Ai} alt="AI" />
                    <div className="desc">
                        <Isvg src={Info} />
                        <p>Generate real estate listing descriptions using AI by simply entering the accurate address and data with just one click</p>
                    </div>
                </div>
                <BasicInfo onChange={handleData} showErrors={showErrors} />
                <div className="wrapper-middle">
                    <div className='card-wrapper'>
                        <div className='title'>Property type</div>
                        {propertyType.map((item, _idx) => {
                            return (
                                <RadioButton
                                    key={_idx}
                                    name={item.name}
                                    onChange={item.onChange}
                                    label={item.label}
                                    value={data.propertyType}
                                />
                            )
                        })}
                        {showErrors && !data.propertyType && <div className='error'>Property type is Required</div>}
                    </div>
                    <div className='card-wrapper'>
                        <div className='title'>Basic house attributes</div>
                        <div className="inputs">
                            <div className='input-wrapper'>
                                <div className='label'>Bedrooms</div>
                                <input type="number" placeholder='Number of bedrooms' value={data.bedrooms} name="bedrooms" onChange={onChangeDetail} />
                                {showErrors && !data.bedrooms && <div className='error'>Number of bedrooms Required</div>}
                            </div>
                            <div className='input-wrapper'>
                                <div className='label'>Bathrooms</div>
                                <input type="number" placeholder='Number of bathrooms' value={data.bathrooms} name="bathrooms" onChange={onChangeDetail} />
                                {showErrors && !data.bathrooms && <div className='error'>Number of bathrooms Required</div>}
                            </div>
                            {measurementConfig.map((item, _idx) => {
                                return (
                                    <div className="input-wrapper" key={item.name}>
                                        <Select data={item.options} name={item.name} selectValueHandler={item.onChange} pointerArrow={PointerArrow} />
                                        <input type="number" placeholder={item.options.find((el) => el.value === data[item.name]).placeholder} value={data[item.textInputName]} name={item.textInputName} onChange={onChangeDetail} />
                                        {showErrors && !data[item.textInputName] && (
                                            <div className="error"> Number of{' '}{item.options.find((el) => el.value === data[item.name]).value}{' '}Required</div>
                                        )}
                                    </div>
                                );
                            })}
                            <div className='input-wrapper'>
                                <div className='label'>Parking</div>
                                <input type="number" placeholder='Number of parking spots' value={data.parking} name="parking" onChange={onChangeDetail} />
                                {showErrors && !data.parking && <div className='error'>Number of parking spots</div>}

                            </div>
                        </div>
                    </div>
                    <div className='last-card-wrapper'>
                        <div className='card-wrapper'>
                            <div className='title'>Description length (words)</div>
                            <div className='buttons'>
                                <button className={descLength === 200 ? 'active' : ''} onClick={() => handleDescLength(200)}>200</button>
                                <button className={descLength === 300 ? 'active' : ''} onClick={() => handleDescLength(300)}>300</button>
                                <button className={descLength === 400 ? 'active' : ''} onClick={() => handleDescLength(400)}>400</button>
                                <button className={descLength === 500 ? 'active' : ''} onClick={() => handleDescLength(500)}>500</button>
                            </div>
                            {showErrors && !data.descriptionLength && <div className='error'>Length is Required</div>}


                        </div>
                        <div className='card-wrapper custom'>
                            <div className='title'>AI Writing Creativity</div>
                            <div className='desc'>Adjust how creative the AI should write. Neutral is recommended for increased accuracy</div>
                            <div className='buttons'>
                                <RadioButton
                                    name="neutral"
                                    onChange={handleWritingCreativity}
                                    label="Neutral"
                                    value={data.writingCreativity}
                                />
                                <RadioButton
                                    name="creative"
                                    onChange={handleWritingCreativity}
                                    label="Creative"
                                    value={data.writingCreativity}
                                />
                            </div>
                            {showErrors && !data.writingCreativity && <div className='error'>Choose the creativity</div>}
                        </div>
                    </div>
                </div>

                <div className="details-wrapper">
                    {details.map((item, _idx) => {
                        return (
                            <Details
                                key={_idx}
                                title={item.title}
                                placeholder={item.placeholder}
                                name={item.name}
                                onChange={item.onChange}
                                maxLength={descLength}
                            />
                        )
                    })}
                </div>
                <div className='result-wrapper'>
                    <div className="submit">
                        <button className='submit' disabled={isDisabled} onClick={handleSubmit}>Generate description</button>
                        {isSubscribed || <div>You still have free trials {currentTries}/{TRIES}</div>}
                        <div className='result-shadow'>
                            <Details
                                name="result"
                                onChange={(event) => handleResult(event.target.value)}
                                value={result}
                                result
                                maxLength={descLength}
                                id="result"
                                disabled={isDisabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
}