export const Listing = ({ image, title, price, views, saves, leades, slug }) => {
  return (
    <div className="listing">
      <a href={`https://www.mallorca-magic.com/property/${slug}`}>
        <div className="image">
          <img src={image} alt="Listing" />
          <div className="overlay">
            <h4>{title}</h4>
            {/* <h4>€ {price} </h4> */}
          </div>
        </div>
        <div className="info">
          <div>
            <h3>{views}</h3>
            <h4>VIEWS</h4>
          </div>
          <div>
            <h3>{saves}</h3>
            <h4>SAVES</h4>
          </div>
          <div>
            <h3>{leades}</h3>
            <h4>LEADES</h4>
          </div>
        </div>
      </a>
    </div>
  )
}