import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { HOST_NAME } from '../config';
import LoadingBar from '../components/LoadingBar';

const VerifyEmail = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    useEffect(() => {
        axios.post(`${HOST_NAME}user/verify_mail/${token}`)
            .then(res => console.log(res))
            .catch(err => {
                console.log(err);
                alert("Token is expired or invalid");
            })
            .finally(() => window.location.href = "https://forms.gozen.io/SO2jT7o1BwQXkpsJeCpY")
    }, [token]);
    return <LoadingBar />;
};
export default VerifyEmail;