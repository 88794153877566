import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useToken } from '../../../hooks/auth/useToken';
import { HOST_NAME } from '../../../config';
import axios from 'axios';


export const ChangePassword = () => {
  const [passwords, setPasswords] = useState({ current: '', new: '', confirm: '' });
  const [token] = useToken();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePasswords = useCallback((e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value })
  }, [passwords])

  const handleSubmit = useCallback(() => {
    setLoading(true);
    setError("");
    const config = {
      method: 'post',
      url: `${HOST_NAME}user/change_pass`,
      headers: {
        'Authorization': 'Bearer ' + token,
      },
      data: {
        password: passwords.current,
        new_password: passwords.new,
        confirm: passwords.confirm
      }
    };
    axios(config)
      .then(res => {
        setError('Password updated successfully');
        setPasswords({ current: '', new: '', confirm: '' });
      })
      .catch(err => setError(err.response.data.error))
      .finally(() => setLoading(false));
  }, [passwords, token, setPasswords]);

  return (
    <div className="account-settings-wrapper">
      <div className="content update-password">
        <h4>Change Password</h4>

        <div className="input">
          <div className="label-wrapper"><label>Current password</label> <Link to="/">Forgot Password?</Link>
          </div>
          <input type="text" name="current" onChange={handlePasswords} value={passwords.current} />
        </div>

        <div className="input">
          <label>New password</label>
          <input type="text" name="new" onChange={handlePasswords} value={passwords.new} />
        </div>

        <div className="input">
          <label>Confirm password</label>
          <input type="text" name="confirm" onChange={handlePasswords} value={passwords.confirm} />
        </div>
        {error && <div className="error">{error}</div>}
        <button disabled={loading} onClick={handleSubmit}>{loading ? "UPDATING..." : "UPDATE PASSWORD"}</button>
      </div>
    </div>
  )
}