import { useOnClickOutside } from 'apps/dashboard/src/utils/useOnClickOutside';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useContryCodeList } from '../../phone-select/contry-code';

export const SearchPhoneSelect = ({ selectValueHandler, name, value: test, phoneNumber, errorHandler }) => {
  const contryCode = useContryCodeList();
  const [isOpen, setIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const options = useRef(null);
  const [value, setValue] = useState(test);

  const searchHandler = useCallback((event) => {
    setValue(event.target.value)
  }, [])


  const showResutls = useCallback(() => {
    setIsOpen(prevState => !prevState)
  }, [])

  const handleSelect = useCallback((selected) => {
    selectValueHandler({ [name]: selected, name: name });
    setValue(selected)
    showResutls();
  }, [name, selectValueHandler, showResutls])

  useEffect(() => {
    setSearchResults([...contryCode])
  }, [contryCode])

  useOnClickOutside(options, showResutls)

  useEffect(() => {
    if (value) {
      if (!phoneNumber) {
        errorHandler();
        return
      }
      return
    }
    if (phoneNumber) {
      if (!value) {
        errorHandler();
        return
      }
      return
    }
    if (!value && !phoneNumber) {
      errorHandler(false);
    }

  }, [errorHandler, phoneNumber, value])

  return (
    <div className="search-phone-wrapper">
      <div className="search-phone" ref={options}>
        <input type="text" name="phone" placeholder="+34" value={value ?? ""} onChange={searchHandler} onFocus={showResutls} />
        {isOpen &&
          <div className="result">
            {searchResults.filter(item => {
              if (!value) return true
              if (item.value.includes(value) || item.text.includes(value)) {
                return true;
              }
            }).map((item, _idx) => (
              <h6 key={_idx} onClick={() => handleSelect(item.value)}>{item.text}</h6>
            ))
            }
          </div>
        }
      </div>
      {value ? !phoneNumber ? <div className="error">Enter the phone number</div> : '' : ''}
      {phoneNumber ? !value ? <div className="error">Choose the area code</div> : '' : ''}
    </div>
  )
}