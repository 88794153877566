import React, { useCallback, useState } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import Globe from '../../../images/svg/globe.svg';
import Stars from '../../../images/svg/social/stars.svg';


export const OneClick = ({ title, subtitle, background, banner }) => {
    const [url, setUrl] = useState('');

    const handleUrl = useCallback((e) => {
        setUrl(e.target.value)
    }, [])

    return (
        <div className={`one-click ${banner ? "first" : ""}`}>
            <img src={background} alt="background" />
            {banner &&
                <div className="totaly-free">
                    <div className="stars">
                        <Isvg src={Stars} />
                    </div>
                    <h1>TOTALLY<br /> FREE</h1>
                    <div className="until">
                        until
                    </div>
                    <div className="date">
                        31.12.2022
                    </div>
                    <div className="stars">
                        <Isvg src={Stars} />
                    </div>
                </div>
            }
            <div className="content">
                <h2>{title} </h2>
                <h3 dangerouslySetInnerHTML={{ __html: subtitle }}></h3>
                <div>
                    <div className="input-wrapper">
                        <Isvg src={Globe} /><input type="text" placeholder="http://yourrealestatewebsite" name="url" onChange={handleUrl} value={url} />
                    </div>
                    <Link to={`/sign-up/${url}`}><button>Create your <span>free</span> account and connect with <span>one</span> click</button></Link>
                </div>
            </div>
        </div>
    )
}