import React, { useCallback, useEffect, useState } from 'react';
// import Isvg from 'react-inlinesvg';
import { useNavigate } from 'react-router-dom';
// import Rocket from '../../images/svg/rocket.svg';
import { Toggle } from '../inputs/toggle';

export const ListingDashboard = ({ data, handleToggleButtons, handleDelete, loading }) => {
  const navigate = useNavigate();
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimenion])

  const handleToggle = useCallback((e) => {
    handleToggleButtons(e);
  }, [handleToggleButtons])

  const handleEdit = useCallback((id) => {
    navigate(`/list-statistic/edit/${id}`)
  },[navigate])

  return (

    <div className="listing-dashboard-wrapper">
      <div className="left">
        <div>
          <img src={data.thumbnail} alt="Listing" />
          {windowDimenion.winWidth > 991 &&
            <div className="buttons">
              <button onClick={() => handleEdit(data._id)} disabled={loading}> Edit </button>
              <button onClick={() => handleDelete(data._id)} disabled={loading}> {loading && <i className="fa fa-spinner fa-spin"></i>} Delete</button>
            </div>}
          {/* {windowDimenion.winWidth > 991 &&
            <Link className="disabled" to={`/list-statistic/promote/${data._id}`}><button> <Isvg src={Rocket} />  Promote</button></Link>} */}
        </div>
        <div className="list">
          <h3>Listing Title</h3>
          <h3>Agent</h3>
          <h3>Location</h3>
          <h3>Page View</h3>
          <h3 id="leads">Leads</h3>
          <h3 id="score">Score</h3>
          <h3>Price</h3>
        </div>
      </div>
      <div className="right">
        <div className="toggle-wrapper">
          <div> {data.title} </div>
          <Toggle checked={data.is_enabled} handleToggleButtons={handleToggle} name={data._id} dashboard disabled={loading} />
        </div>
        <div> {data.agent} </div>
        <div> {data.location} </div>
        <div className="info"> <h3> {data.pageView} </h3>
          <div className="leads">
            <div className="blue">Leads</div>
            <h3> {data.leads} </h3>
          </div>
          <div className="score">
            <div className="blue">Score </div>
            <h3> {data.score} </h3>
          </div>
        </div>
        <div className="price">{data.price}</div>
      </div>
      {windowDimenion.winWidth < 991 &&
        <div className="buttons">
           <button onClick={() => handleEdit(data._id)} disabled={loading}> Edit </button>
           <button onClick={() => handleDelete(data._id)} disabled={loading}> {loading && <i className="fa fa-spinner fa-spin"></i>} Delete</button>
        </div>}
      {/* {windowDimenion.winWidth < 991 &&
        <button> <Isvg src={Rocket} />  Promote</button>} */}
    </div>
  )
}