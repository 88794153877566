import React, { useCallback, useState, useRef } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { CarouselSlider } from '../components/carousel';
import { EmailInput } from '../components/inputs/email';
import Slider1 from '../images/slider-1.png';
import Slider2 from '../images/slider-2.png';
import Slider3 from '../images/slider-3.png';
import Slider4 from '../images/slider-4.png';
import Slider5 from '../images/slider-5.png';
import ArrowLeft from '../images/svg/arrow-left.svg';
import axios from 'axios';
import { HOST_NAME, GOOGLE_CAPTCHA_SITE_KEY } from '../config';
import ReCAPTCHA from "react-google-recaptcha";

const slider = [
  {
    src: Slider1
  },
  {
    src: Slider2
  },
  {
    src: Slider3
  },
  {
    src: Slider4
  },
  {
    src: Slider5
  }
]

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailRegex.test(email)) return true;
  return false;
}

export const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [recapcthaToken, setRecapcthaToken] = useState('');
  const recaptcha = useRef(null);

  const handleEmail = useCallback((value) => {
    setEmail(value)
  }, [])

  const handleSubmit = useCallback(() => {
    if (!validateEmail(email)) {
      alert("Please enter a valid email address");
      return;
    }

    setLoading(true);
    setError("");
    axios.post(`${HOST_NAME}user/forgot_pass`, { email: email, token: recapcthaToken })
      .then(res => {
        if (res.status === 200) setSuccess(true);
        // error handler
      })
      .catch(err => {
        console.log(err);
        setError(err.response.data.error);
      })
      .finally(() => {
        setLoading(false);
        recaptcha.current.reset();
        });
  }, [email, recapcthaToken, recaptcha]);


  return (
    <div className="forgot-password-wrapper">
      <div className="left">
        <CarouselSlider slider={slider} />
      </div>
      <div className="right">
        <Link to="/login"><button className="arrow-left"><Isvg src={ArrowLeft} /></button></Link>

        <div className="header">
          <div><h1>Forgot Password?</h1> </div>
          <p>Enter the email address you used when you joined
            and we’ll send you instructions to reset your password.</p>

          <p>For security reasons, we do NOT store your password.
            So rest assured that we will never send your password
            via email.</p>
          <div className="form">
            <EmailInput setEmail={handleEmail} email={email} label="Email Address" />
            <ReCAPTCHA ref={recaptcha} sitekey={GOOGLE_CAPTCHA_SITE_KEY} onChange={setRecapcthaToken} />
            {
              success &&
              <p>Please check you mail for further instructions</p>
            }
            {
              error &&
              <p className='error' style={{color: "red"}}>{error}</p>
            }
            <button className="login" onClick={handleSubmit} disabled={loading}> {loading ? "Sending Reset Instructions..." : "Send Reset Instructions"}</button>
            <div className="dont-account">
              <h6> Don’t have an account? <Link to="/sign-up">Register</Link></h6>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}