import React from 'react';
import { Footer } from '../Footer';
import { Navbar } from '../Navbar';

export const Layout = ({ children }) => {
  return (
    <>
      <header><Navbar /></header>
      <main>{children}</main>
      <footer><Footer /></footer>
    </>
  )
}
