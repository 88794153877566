import React from 'react';
import Isvg from 'react-inlinesvg';
import HHLogo from '../../../images/svg/HHLogo.svg';
import Fb from '../../../images/svg/social/facebook.svg';
import Instagram from '../../../images/svg/social/instagram.svg';
import Pintrest from '../../../images/svg/social/pintrest.svg';
import Tiktok from '../../../images/svg/social/tiktok.svg';
import Twiter from '../../../images/svg/social/twiter.svg';

export const Footer = () => {
    return (
        <div className="account-page-footer">
            <div className="content">
                <div className="left">
                    <Isvg src={HHLogo} />
                    <p>we make real estate social shoppable</p>
                    <div className="social-network">
                        <a href="https://www.facebook.com/mallorca.is.magic" target="_blank" rel="noopener noreferrer"><Isvg src={Fb} /></a>
                        <a href="https://www.instagram.com/mallorcamagic/" target="_blank" rel="noopener noreferrer"><Isvg src={Instagram} /></a>
                        <a href="https://www.tiktok.com/@mallorca.magic" target="_blank" rel="noopener noreferrer"><Isvg src={Tiktok} /></a>
                        <a href="https://www.pinterest.de/mallorca_magic/" target="_blank" rel="noopener noreferrer"><Isvg src={Pintrest} /></a>
                        <a href="https://twitter.com/mallorca_magic" target="_blank" rel="noopener noreferrer"> <Isvg src={Twiter} /></a>
                    </div>
                </div>
                <div className="right">
                    <h2>Contact Info</h2>
                    <p>Cami Vell de Alcudia Buzon 58</p>
                    <p>07300 Inca,  Mallorca, Spain</p>
                    <p>info@hellohere.es</p>
                    <a href="phone: +34 693010075">+34 693010075</a>
                </div>
            </div>
            <div className="rights">
                <h6>© 2022 Hellohere, All Rights Reserved</h6>
            </div>
        </div>
    )
}