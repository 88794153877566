import { useState } from "react";

export const useToken = () => {
    const [token, setTokenInternal] = useState(localStorage.getItem("token"));

    const setToken = (t) => {
        localStorage.setItem("token", t);
        setTokenInternal(t);
    }

    return [token, setToken];
}
