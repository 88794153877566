import React from 'react';

const contry = [
  {
    contry: "Germany",
    percent: 11.00,
    views: 11,
    color: "#d11a2a"
  },
  {
    contry: "Spain",
    percent: 11.00,
    views: 11,
    color: "#FFCE31"
  },
  {
    contry: "United Kingdom",
    percent: 11.00,
    views: 11,
    color: "#0F91D2"
  },
  {
    contry: "Natherlands",
    percent: 11.00,
    views: 11,
    color: "#7ED957"
  },
  {
    contry: "France",
    percent: 11.00,
    views: 11,
    color: "#ED4756"
  },
  {
    contry: "Poland",
    percent: 11.00,
    views: 11,
    color: "#FFA400"
  },
  {
    contry: "Hungery",
    percent: 11.00,
    views: 11,
    color: "#FF2121"
  },
  {
    contry: "Sweden",
    percent: 11.00,
    views: 11,
    color: "#69BE94"
  },
  {
    contry: "Others",
    percent: 11.00,
    views: 11,
    color: "#3E54C6"
  }
]

export const PieChart = () => {

  return (
    <div className="coming-soon-mobile-wrapper">
      <div className="coming-soon-mobile">COMING SOON</div>
      <div className="pie-chart-wrapper">
        <h6>PAGE VIEW BY COUNTRY</h6>
        <div className="my-pie-chart-container">

          <div className="contries">
            {contry.map((item, _idx) => {
              return (
                <div key={_idx} className="contry">
                  <div className="contry-name">
                    <span style={{ background: item.color }}></span>
                    <h6>{item.contry}</h6>
                  </div>
                  <div className="text">
                    <h5>{item.percent}%</h5>
                    <h4>{item.views}</h4>
                  </div>
                </div>

              )
            })}
          </div>
          <div id="my-pie-chart" style={{ "background": `radial-gradient(white 45%, transparent 45%), conic-gradient( \n #d11a2a 0 11.00%,\n    #ffce31 0 22.00%,\n    #0f91d2 0 33.00%,\n    #7ed957 0 44.00%,\n    #ed4756 0 55.00%,\n    #ffa400 0 66.00%,\n    #ff2121 0 77.00%,\n    #69be94 0 88.00%,\n    #3e54c6 0 100.00%\n  )` }}></div>
        </div>
      </div>
    </div>
  )
}



