import { useCallback, useRef, useState } from "react";

export const Details = ({ title, placeholder, name, onChange, value, result, maxLength, id, disabled }) => {
    const ref = useRef(null)
    const [currLength, setCurrLength] = useState(0);

    const handleCopy = useCallback(async () => {
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(ref.current.value);
        } else {
            alert('The clipboard API is not available. Please allow access in your browser!')
        }
    }, [])

    const handleChange = useCallback((event) => {
        onChange(event)
        setCurrLength(event.target.value.length)

    }, [onChange])

    return (
        <div className="details">
            {title && <div className="title">{title}</div>}
            <div className="textarea">
                <textarea id={id ? id : ''} placeholder={placeholder} name={name} onChange={handleChange} value={value} ref={ref} maxLength={maxLength} disabled={disabled} />
                {!result && <div className="numbers">{currLength} / {maxLength}</div>}
            </div>
            {result && <button className="copy" onClick={handleCopy}>Copy</button>}
        </div>

    )
}