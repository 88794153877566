import { useMediaQuery } from 'apps/dashboard/src/utils/useMediaQuery';
import { useCallback, useState } from 'react';
import Isvg from 'react-inlinesvg';
import DownArrow from '../../../../images/svg/down-arr.svg';
import Download from '../../../../images/svg/download.svg';
import Print from '../../../../images/svg/print.svg';

export const Row = ({ item }) => {
  const isMobile = useMediaQuery(767);
  const [openDropDown, setOpenDropDown] = useState(false);

  const handleDropDown = useCallback(() => {
    setOpenDropDown((prevState) => !prevState);
  }, []);
  return (
    <>
      <div className="row-wrapper">
        <div className="row">
          <div>{item.id}</div>
          <div className={item.paymentStatus}>{item.paymentStatus}</div>
          <div>{item.plan}</div>
          {!isMobile && <div>{item.price}{' '}EUR</div>}
        </div>
        {!isMobile && (
          <div className="options">
            {/* <button>
              <Isvg src={Print} />
            </button> */}
            <button onClick={()=>{window.open(item.invoicePDF)}}>
              <Isvg src={Download} />
            </button>
          </div>
        )}
        {isMobile && (
          <button className="drop" onClick={handleDropDown}>
            <Isvg src={DownArrow} />
          </button>
        )}
      </div>
      {isMobile && openDropDown && (
        <div className="menu">
          <p>Payment status : <span className={item.paymentStatus}>{item.paymentStatus}</span></p>
          <p>Purpose of payment : {item.plan}</p>
          <p>Total : {item.price}{' '} EUR</p>
          <div className="options">
            {/* <button>
              <Isvg src={Print}/>
            </button> */}
            <button>
              <Isvg src={Download} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
