import React from 'react'
import { Link } from 'react-router-dom'
import { ListPreview } from '../../../components/list-preview'
import Isvg from 'react-inlinesvg'
import WhiteStatistic from '../../../images/svg/statistic-white.svg';
import Statistic from '../../../images/svg/statistic.svg';
import WhiteEdit from '../../../images/svg/edit-white.svg';
import Edit from '../../../images/svg/edit.svg';
import Rocket from '../../../images/svg/ion_rocket-outline.svg';
import WhiteRocket from '../../../images/svg/rocket-white.svg';
import { Promote as PromoteComponent } from '../../../components/promote'
import Trash from '../../../images/svg/trash.svg';
import Save from '../../../images/svg/save.svg';


export const Promote = ({ previewData, id, type }) => {
  return (
    <div>
      <ListPreview listing={previewData}>
        <div className="buttons">
          <button> <Isvg src={Trash} /> Delete</button>
          <button> <Isvg src={Save} />Save Changes</button>
        </div>
      </ListPreview>
      <div className="tabs">
        <Link to={`/list-statistic/statistic/${id}`} className={type === "statistic" ? "active" : ""}><button > {type === "statistic" ? <Isvg src={WhiteStatistic} /> : <Isvg src={Statistic} />}  STATISTICS</button></Link>
        <Link to={`/list-statistic/edit/${id}`} className={type === "edit" ? "active" : ""}><button > {type === "edit" ? <Isvg src={WhiteEdit} /> : <Isvg src={Edit} />} Edit listing</button></Link>
        <Link to={`/list-statistic/promote/${id}`} className={type === "promote" ? "active" : ""}><button> {type === "promote" ? <Isvg src={WhiteRocket} /> : <Isvg src={Rocket} />} Promote</button></Link>
      </div>
      <PromoteComponent />
    </div>
  )
}