import { useMemo } from "react";
import MagicLogo from '../../../images/svg/magic-logo.svg';

export const useSubscriptions = () => useMemo(() => ([{
  title: 'Hello:listing free',
  description: 'Try our free version now and discover the many possibilities of our product! Convince yourself and try it our-risk-free and completely free',
  free: true,
  price: {
    'monthly': '€0',
    'yearly': '€0'
  },
  price_id: '',
  type: 'listings',
  includes: ['5 listed properties on our marketplaces : malloramagic lifeofspain and mallorcaandwine', 'Free leads']
},
{
  title: 'Hello:listing basic',
  description: 'unlimited real estate listings with us! List as many proper as you want',
  price: {
    'monthly': '€199',
    'yearly': '€1,999'
  },
  product_id: 'prod_NGbQCBWBJGTLj9',
  type: 'listings',
  free: false,
  price_id: {
    'monthly':'price_1MW4DAHfaSP6XcAk2pSuk5BY',
    'yearly':'price_1MW4DAHfaSP6XcAkEIZ2DPhu',
  },
  includes: [
    'Unlimited properties on our marketplace : mallorcamagic lifeofspain and mallorcaandwine',
    'Unlimited properties of our instagram and facebook shop',
    'Incl. company logo',
    'Incl. company website link',
    'Incl. company social link',
    'Unlimited free leads'
  ]
},
{
  title: 'Hello:listing Premium',
  description: 'Our premium plan includes listing your properties on the brand ambassadors @aufilduglobe and @julifrommallorca. Benefit from additional visibility and reach more potential customers',
  price: {
    'monthly': '€299',
    'yearly': '€3,199'
  },
  product_id: 'prod_NGbQoLG8RDRJpd',
  price_id: {
    'monthly': 'price_1MW4DKHfaSP6XcAkSbbaBcm4',
    'yearl': 'price_1MW4DKHfaSP6XcAk288UzLW9'
  },
  type: 'listings',
  includes: [
    'Unlimited properties on our marketplace : mallorcamagic lifeofspain and mallorcaandwine',
    'Unlimited properties of our ambassador shop : Unlimited properties on our maketplace: @aufilduglobe and @julifrommallorca',
    'Unlimited properties of our instagram and facebook shop',
    'Incl. company logo',
    'Incl. company website link',
    'Incl. company social link',
    'Unlimited free leads'
  ]
}
]), [])


export const useMagicSubscriptions = () => useMemo(() => ([{
  title: 'Hello:Magic',
  logo: MagicLogo,
  price_id: {
    'monthly': 'price_1MW4DOHfaSP6XcAkCljDc6SF',
    'yearly': 'price_1MW4DOHfaSP6XcAkCs9J1NkQ',
  },
  description: 'Unlimited experience the magic of AI-powered real estate description with Hello:magic!',
  product_id: 'prod_NGbQjdcjZb4J43',
  type: 'magic',
  price: {
    'monthly': '€49 ',
    'yearly': '€499'
  },
  includes: ['Unlimited description', 'Description in English, Spanish and German', 'Cancel anytime', 'Priority support']
},
]), [])