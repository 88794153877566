import React, { useCallback, useState } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { CarouselSlider } from '../components/carousel';
import Slider1 from '../images/slider-1.png';
import Slider2 from '../images/slider-2.png';
import Slider3 from '../images/slider-3.png';
import Slider4 from '../images/slider-4.png';
import Slider5 from '../images/slider-5.png';
import ArrowLeft from '../images/svg/arrow-left.svg';
import axios from 'axios';
import { HOST_NAME } from '../config';
import { Password } from '../components/inputs/password';
import { useParams, useNavigate } from 'react-router-dom';

const slider = [
  {
    src: Slider1
  },
  {
    src: Slider2
  },
  {
    src: Slider3
  },
  {
    src: Slider4
  },
  {
    src: Slider5
  }
]

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailRegex.test(email)) return true;
  return false;
}

export const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleConfirmPassword = useCallback((value) => {
    setConfirmPassword(value);
  }, []);

  const handlePassword = useCallback((value) => {
    setPassword(value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    setLoading(true);
    setError("");
    axios.post(`${HOST_NAME}user/reset_pass`, { password: password, token: token })
      .then(res => navigate("/login"))
      .catch(err => setError(err.response.data.error))
      .finally(() => setLoading(false));
  }, [confirmPassword, navigate, password, token]);


  return (
    <div className="forgot-password-wrapper">
      <div className="left">
        <CarouselSlider slider={slider} />
      </div>
      <div className="right">
        <Link to="/login"><button className="arrow-left"><Isvg src={ArrowLeft} /></button></Link>

        <div className="header">
          <div><h1>Reset your password</h1> </div>
          <div className="form">
            <Password setPassword={handlePassword} password={password} label="A secure password" />
            <Password setPassword={handleConfirmPassword} password={confirmPassword} label="Confirm your password" />
            {error && <div className="error">{error}</div>}
            <button className="login" onClick={handleSubmit} disabled={loading}> {loading ? "Resetting..." : "Reset password"}</button>
            <div className="dont-account">
              <h6> Don’t have an account? <Link to="/sign-up">Register</Link></h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}