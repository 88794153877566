import React, { useCallback, useState } from 'react';
import Isvg from 'react-inlinesvg';
import Lock from '../../../images/svg/lock.svg';
import Eye from '../../../images/svg/eye.svg';


export const Password = ({ setPassword, password, label, handleConfirmPassword, confirms }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePassword = useCallback((e) => {
    setPassword(e.target.value)
  }, [setPassword])

  const handleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword])

  return (
    <div className="password-input-wrapper">
      <label>{label}</label>
      <div className="input-password">
        <Isvg src={Lock} />
        <input type={showPassword ? "text" : "password"} name="password" value={password} onChange={handlePassword} />
        <button onClick={handleShowPassword}> <Isvg src={Eye} /> </button>
      </div>
    </div>
  )
}