import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import './App.css';
import { Home } from './views/Home'
import { Login } from './views/Login';
import { SignUp } from './views/SignUp';
import { ForgotPassword } from './views/ForgotPassword'
import { DashboardListing } from './views/DashboardListing'
import { AccountSettings } from './views/AccountSettings'
import { ListStatistic } from './views/ListStatistic'
import { CreateNewListing } from './views/CreateNewListing'
import { PageAccounts } from './views/PageAccount/index';
import { useToken } from './hooks/auth/useToken';
import NotFound from './views/404';
import VerifyEmail from './views/VerifyEmail';
import { ResetPassword } from './views/ResetPassword';
import  { AIListings } from './views/AIListings'
import axios from 'axios';

export const App = () => {
  const [token,] = useToken();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (token) {
      axios.interceptors.request.use(config => {
        config.headers.Authorization = 'Bearer ' + token;
        return config;
      });
    } else {
      axios.interceptors.request.use(config => {
        config.headers.Authorization = '';
        delete config.headers.Authorization;
        return config;
      });
    }
  }, [token]);

  // load google map libs
  useEffect(() => {
    let ref = window.document.getElementsByTagName("script")[0];
    let script = window.document.createElement("script");
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCzKrCwhiFuLc5VgrncY-VZA-qzegAypCM&v=weekly&libraries=places";
    script.async = true;
    script.defer = true;
    script.addEventListener("load", ()=>{setLoading(true)});
    ref.parentNode.insertBefore(script, ref);
  }, [])


  if(!loading) {
    return <div></div>
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<PageAccounts />} />
        <Route path="/home" element={<PageAccounts />} />
        <Route path="/dashboard" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-up/:url" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/dashboard-listing" element={<DashboardListing />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/list-statistic/:type/:id" element={<ListStatistic />} />
        <Route path="/create-listing" element={<CreateNewListing />} />
        <Route path="/verify-mail/:token" element={<VerifyEmail />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/hello-magic" element={<AIListings />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
}
