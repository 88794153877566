import LoadingBar from 'apps/dashboard/src/components/LoadingBar';
import { HOST_NAME } from 'apps/dashboard/src/config';
import { useToken } from 'apps/dashboard/src/hooks/auth/useToken';
import { useBillingModal } from 'apps/dashboard/src/modals/Billing/Provider';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import Isvg from 'react-inlinesvg';
import BillingIcon from '../../../images/svg/billing.svg';
import Subscribed from '../../../images/svg/Group.svg';
import Info from '../../../images/svg/info.svg';
import { Package } from './Package';
import { useMagicSubscriptions, useSubscriptions } from './useSubscriptionConfig';

export const Subscription = () => {
  const { openModal } = useBillingModal()
  const [tabs, setTabs] = useState('');
  const subscriptions = useSubscriptions();
  const maigSubscriptions = useMagicSubscriptions();
  const [isSubscribed, setIsSubscribed] = useState([]);
  const [user, setUser] = useState(null);
  const [token] = useToken();

  useEffect(() => {
    const config = {
      method: 'get',
      url: `${HOST_NAME}user`,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };
    axios(config)
      .then((res) => setUser(res.data))
      .catch((err) => console.log(err));
  }, [token]);

  useEffect(() => {
    if(user && user.customer_id) {
      user.subscriptions.forEach((sub) => { 
        if(maigSubscriptions.find((item) => item.price_id.monthly === sub.price_id || item.price_id.yearly === sub.price_id )) {
          setIsSubscribed((prevValue)=>[...prevValue, 'magic'])
        }

        if(subscriptions.find((item) => item.price_id.monthly === sub.price_id || item.price_id.yearly === sub.price_id )) {
          setIsSubscribed((prevValue)=>[...prevValue, 'listings'])
        }

      })
    }
  }, [maigSubscriptions, subscriptions, user])

  const handleTabs = useCallback((tab) => {
    setTabs(tab)
  }, [])

  if(!user) return <LoadingBar></LoadingBar>

  return (
    <div className="subscription-wrapper">
      <div className="title">Hello Tools Subscription</div>
      <div className="buttons">
        <button className={`${tabs === 'listings' ? `active ${isSubscribed.includes('listings') ? 'subscribed' : ''}` : isSubscribed.includes('listings') ? 'subscribed' : ''}`} onClick={() => handleTabs('listings')}> {isSubscribed.includes('listings') && <Isvg src={Subscribed} />} Hello:listings</button>
        <button className={`${tabs === 'magic' ? `active ${isSubscribed.includes('magic') ? 'subscribed' : ''}` : isSubscribed.includes('magic') ? 'subscribed' : ''}`} onClick={() => handleTabs('magic')}> {isSubscribed.includes('listings') && <Isvg src={Subscribed} />}hello:magic</button>
        <button onClick={openModal}> <Isvg src={BillingIcon} /> Billing</button>
      </div>

       {tabs && (
        <div className="box">
          {tabs === 'listings' && subscriptions.map((item, _idx) => <Package item={item} key={_idx} user={user}/>)}
          {tabs === 'magic' && maigSubscriptions.map((item, _idx) => <Package item={item} key={_idx} user={user}/>)}
        </div>)}

      <p className='info'> <Isvg src={Info} />  To subscribe to one of the HelloHere tools, simply click on the button</p>
    </div>
  );
};
