import React from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { DateFilter } from '../../../components/date-filter';
import { LineChart } from '../../../components/line-chart';
import { ListPreview } from '../../../components/list-preview';
import { Overview } from '../../../components/overview';
import { PieChart } from '../../../components/pie-chart';
import WhiteEdit from '../../../images/svg/edit-white.svg';
import Edit from '../../../images/svg/edit.svg';
import Export from '../../../images/svg/export.svg';
import Rocket from '../../../images/svg/ion_rocket-outline.svg';
import WhiteRocket from '../../../images/svg/rocket-white.svg';
import Save from '../../../images/svg/save.svg';
import WhiteStatistic from '../../../images/svg/statistic-white.svg';
import StatisticIcon from '../../../images/svg/statistic.svg';
import Trash from '../../../images/svg/trash.svg';

export const Statistic = ({ data, previewData, id, type }) => {
  return (
    <>
      <ListPreview listing={previewData}>
        <div className="buttons">
          <button> <Isvg src={Trash} /> Delete</button>
          <button> <Isvg src={Save} />Save Changes</button>
        </div>
      </ListPreview>
      <div className="tabs">
        <Link to={`/list-statistic/statistic/${id}`} className={type === "statistic" ? "active" : ""}><button > {type === "statistic" ? <Isvg src={WhiteStatistic} /> : <Isvg src={StatisticIcon} />}  STATISTICS</button></Link>
        <Link to={`/list-statistic/edit/${id}`} className={type === "edit" ? "active" : ""}><button > {type === "edit" ? <Isvg src={WhiteEdit} /> : <Isvg src={Edit} />} Edit listing</button></Link>
        <Link to={`/list-statistic/promote/${id}`} className={type === "promote" ? "active" : ""}><button> {type === "promote" ? <Isvg src={WhiteRocket} /> : <Isvg src={Rocket} />} Promote</button></Link>
      </div>
      <div className="filter">
        <DateFilter />
        <div className="export">
          <button> <Isvg src={Export} /> EXPORT CSV</button>
        </div>
      </div>
      <Overview />
      <div className="statistic-charts-wrapper">
        <h3>Detailed Metrics</h3>
        <div className="statistic-charts">
          {data.map((item, _idx) => {
            return (
              <LineChart key={_idx} data={item} />
            )
          })}
          <PieChart />
        </div>
      </div>
    </>
  )
}