import React, { useCallback, useState } from 'react'
import ISvg from 'react-inlinesvg';
import Circle from '../../images/svg/add-circle.svg';
import Minus from '../../images/svg/minus.svg';

export const Faq = ({ q, a }) => {
  const [expand, setExpand] = useState(false);

  const handleExpand = useCallback(() => {
    setExpand(!expand);
  }, [expand])

  return (
    <div className="faq-wrapper">
      <div className="question">
        <div>
          <h1>{q}</h1>
          <button onClick={handleExpand}>{expand ? <ISvg src={Minus} /> : <ISvg src={Circle} />}
          </button>
        </div>
        {expand &&
          <div className="answer">
            <p>{a}</p>
          </div>
        }
      </div>

    </div>
  )
}