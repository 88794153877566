import React, { useCallback } from 'react';
import Isvg from 'react-inlinesvg'
import Info from '../../../images/svg/info.svg';

export const Input = ({ svg, value, handleValue, label, name, info, desc }) => {

    const handleInput = useCallback((e) => {
        handleValue(e.target.value);
    }, [handleValue])

    return (
        <div className="input-text-wrapper">
            {label && <label>{label}</label>}
            {info && <div className="info"> <Isvg src={Info} /><p>{info}</p></div>}
            <div className="input-wrapper">
                <Isvg src={svg} />
                <input type="text" name={name} value={value} onChange={handleInput} />
            </div>
            {desc && <div className="desc"><p>{desc}</p></div>}
        </div>
    )
}