import { useCallback, useEffect, useRef } from 'react'
import Isvg from 'react-inlinesvg';
import GoogleMaps from '../../../images/svg/logos_google-maps.svg';
import Eye from '../../../images/svg/eye.svg';
import Search from '../../../images/svg/search.svg';

export const LocationInput = ({ setLocation, location }) => {
  const placeInputRef = useRef(null);

  useEffect(() => {
    initPlaceAPI();
  }, []);

  const handleLocation = useCallback((e) => {
    setLocation(e.target.value)
  }, [setLocation])

  // initialize the google place autocomplete
  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(placeInputRef.current, {type : ['postal_town']});
    new window.google.maps.event.addListener(autocomplete, "place_changed", function () {
      let place = autocomplete.getPlace();
      
      setLocation({
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      });
    });
  };

  return (
    <div className="input-location-wrapper">
      <label>Your Location</label>
      <div className="location-wrapper">
        <Isvg src={GoogleMaps} />
        <input ref={placeInputRef} type="text" name="Location" value={location} onChange={handleLocation} />
        <button> <Isvg src={Search} /> </button>
      </div>
    </div>

  )
}