import { MutableRefObject, useEffect } from 'react';

type IOutsideClickRef = MutableRefObject<HTMLElement> | MutableRefObject<null>;
type IOutsideClickHandler = () => void;

export const useOnClickOutside = (ref: IOutsideClickRef, onOutsideClick: IOutsideClickHandler) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, onOutsideClick]);
};
