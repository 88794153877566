import Isvg from 'react-inlinesvg';
import Information from '../../images/svg/information.svg';
export const Overview = ({ data }) => {

  return (
    <div className="overview-wrapper">
      <h3>Overview</h3>
      <div className="overview">
        {data.map((item, _idx) => {
          return (
            <div className="box" key={`${_idx}-${item.title}`}>
              <div className="header">
                <h3>{item.title}</h3>
                <Isvg src={Information} />
              </div>
              <h6>{item.value}</h6>
            </div>
          )
        })}
      </div>
    </div>
  )
}