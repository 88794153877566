import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import Isvg from 'react-inlinesvg';
import ArrowDown from '../../images/svg/arrow-down.svg';
import ArrowUp from '../../images/svg/arrow-up.svg';
import Calendar from '../../images/svg/calendar.svg';
import { useOnClickOutside } from '../../utils/useOnClickOutside';
import { useFilterConfig } from './useFiletConfig';

export const DateFilter = ({ manageDateRange }) => {
  const { days, months, weeks, filterKeywords } = useFilterConfig()
  const [filter, setFilter] = useState('Last month');
  const [open, setOpen] = useState(false);
  const [typeFilter, setTypeFilter] = useState('Month')
  const [filterValues, setFilterValues] = useState(months);
  const [result, setResult] = useState(moment().subtract(7, 'd').format('YYYY-MM-DD'));
  const options = useRef(null);

  const handleFilter = useCallback((text, value) => {
    setFilter(text)
    setResult(moment().subtract(value, 'd').format('YYYY-MM-DD'))

    manageDateRange({ start: moment().subtract(value, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })
  }, [manageDateRange])

  const handleTypeFilter = useCallback((value) => {
    if (value === 'Day') {
      setFilterValues(days)
    }
    if (value === 'Week') {
      setFilterValues(weeks)
    }
    if (value === 'Month') {
      setFilterValues(months)
    }
    setTypeFilter(value)
  }, [days, months, weeks])

  const handleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])


  useEffect(() => {
    if (typeFilter === 'Day') {
      setResult(moment().subtract(days[0].value, 'd').format('YYYY-MM-DD'))
      setFilter(days[0].text);
      manageDateRange({ start: moment().subtract(days[0].value, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })
    }
    if (typeFilter === 'Week') {
      setResult(moment().subtract(weeks[0].value, 'd').format('YYYY-MM-DD'))
      setFilter(weeks[0].text);
      manageDateRange({ start: moment().subtract(weeks[0].value, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })
    }
    if (typeFilter === 'Month') {
      setResult(moment().subtract(months[0].value, 'd').format('YYYY-MM-DD'))
      setFilter(months[0].text);
      manageDateRange({ start: moment().subtract(months[0].value, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })
    }
  }, [days, manageDateRange, months, typeFilter, weeks])

  useOnClickOutside(options, () => setOpen(false))

  return (
    <div className="wrapper-date-input">

      <div className="content">
        <div className="dropdown-wrapper" onClick={handleOpen} ref={options}>
          <Isvg src={Calendar} />
          <h2>{filter}</h2>
          {open &&
            <div className="dropdown">
              {filterValues.map((item, _idx) => {
                return (
                  <h6 key={_idx} onClick={() => handleFilter(item.text, item.value)}>{item.text}</h6>
                )
              })}
            </div>
          }
          {open ?
            <Isvg src={ArrowUp} />
            :
            <Isvg src={ArrowDown} />
          }
        </div>
      </div>
      <div className="buttons">
        {filterKeywords.map((item, _idx) => {
          return (
            <button key={_idx} className={item.text === typeFilter ? "active" : ""} onClick={() => handleTypeFilter(item.text)}>{item.text}</button>
          )
        })}
      </div>
      <div className="range"> <h3>{moment(result).format('D')} {moment(result).format('MMMM')} - {moment().date()} {moment().format('MMMM')}</h3> </div>
    </div>

  )
}