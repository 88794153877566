import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from "react-csv";
import Isvg from 'react-inlinesvg';
import { DateFilter } from '../components/date-filter';
import { LineChart } from '../components/line-chart';
import { Listing } from '../components/listing';
import { Overview } from '../components/overview';
import { HOST_NAME } from '../config';
import { DefaultLayout } from '../containers/DefaultLayout';
import { useToken } from '../hooks/auth/useToken';
import Export from '../images/svg/export.svg';
import Statistic from '../images/svg/statistic.svg';

const headers = [
  { label: "Property Title", key: "propertyTitle" },
  { label: "URL-Mallorcamagic", key: "urlMallorcamagic" },
  // { label: "Total page clicks", key: "totalPageClicks" },
  { label: "Total page views", key: "totalPageViews" },
  { label: "Total page leads", key: "totalPageLeads" },
  { label: "Total page saves", key: "totalPageSaves" },
];

export const Home = () => {
  const [token,] = useToken();
  const [topPerforming, setTopPerforming] = useState([]);
  const [counts, setCounts] = useState([])
  const [dateRange, setDateRange] = useState({ start: moment().subtract(7, 'd').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') });

  const csvData = useMemo(() => topPerforming.map((item) => ({
    propertyTitle: item.property.title || '',
    urlMallorcamagic: `${`https://www.mallorca-magic.com/property/${item.property.slug}`}` || '',
    // totalPageClicks: item.business_page,
    totalPageViews: item.views || 0,
    totalPageLeads: item.leads || 0,
    totalPageSaves: item.saves || 0,
  })), [topPerforming])

  const csvReport = useMemo(() => ({
    data: csvData,
    headers: headers,
    filename: 'MallorcamagicStatistic.csv'
  }), [csvData])

  const manageDateRange = useCallback((value) => {
    setDateRange(value)
  }, [])

  const fetchTopPerforming = useCallback(async (searchParams) => {
    try {
      const { data } = await axios({
        url: `${HOST_NAME}stat/top-performing`,
        method: "post",
        headers: {
          'Authorization': 'Bearer ' + token,
        },
        data: searchParams
      });
      setTopPerforming(data)

    } catch (error) {
      console.error(error.message);
    }
  }, [token])

  useEffect(() => {
    fetchTopPerforming({ limit: 4, ...dateRange })
  }, [fetchTopPerforming, dateRange]);


  const fetchCounts = useCallback(async (searchParams) => {
    try {
      const { data } = await axios({
        url: `${HOST_NAME}stat/counts`,
        method: "post",
        headers: {
          'Authorization': 'Bearer ' + token,
        },
        data: searchParams
      });
      setCounts(data)

    } catch (error) {
      console.error(error.message);
    }

  }, [token])

  useEffect(() => {
    fetchCounts(dateRange)
  }, [dateRange, fetchCounts]);


  const chartData = useMemo(() => (
    [
      {
        labels: counts.map((item, _idx) => item._id, []),
        datasets: [
          {
            data: counts.map((item, _idx) => item.views, []),
            borderColor: '#3176BC',
            backgroundColor: '#3176BC',
          }
        ],
        title: "PAGE VIEWS",
        value: counts.reduce((acc, curr) => acc + curr.views, 0)
      },
      {
        labels: counts.map((item, _idx) => item._id, []),
        datasets: [
          {
            data: counts.map((item, _idx) => item.saves, []),
            borderColor: '#3176BC',
            backgroundColor: '#3176BC',
          }
        ],
        title: "LISTING SAVES",
        value: counts.reduce((acc, curr) => acc + curr.saves, 0)
      },
      {
        labels: counts.map((item, _idx) => item.business_page, []),
        datasets: [
          {
            data: counts.map((item, _idx) => item.business_page, []),
            borderColor: '#3176BC',
            backgroundColor: '#3176BC',
          }
        ],
        title: "BUSINESS PAGE CLICKS",
        value: counts.reduce((acc, curr) => acc + curr.business_page, 0)
      },
      {
        labels: counts.map((item, _idx) => item.leads, []),
        datasets: [
          {
            data: counts.map((item, _idx) => item.leads, []),
            borderColor: '#3176BC',
            backgroundColor: '#3176BC',
          }
        ],
        title: "LEADS",
        value: counts.reduce((acc, curr) => acc + curr.leads, 0)
      },

    ]
  ), [counts])

  const overview = useMemo(() => chartData.map((item) => ({ title: item.title, value: item.value }), []), [chartData])

  return (
    <DefaultLayout isPrivate={true}>
      <div className="home-content-wrapper">
        <div className="title"> <h6>Dashboard</h6> </div>
        <div className="subtitle"> <Isvg src={Statistic} /> <h6>OVERALL STATISTICS</h6> </div>
        <div className="home-content">
          <div className="filter">
            <DateFilter manageDateRange={manageDateRange} />
            <div className="export">
              <CSVLink {...csvReport}><Isvg src={Export} /> EXPORT CSV</CSVLink>
            </div>
          </div>
          <Overview data={overview} />
          <div className="statistic-charts-wrapper">
            <h3>Detailed Metrics</h3>
            <div className="statistic-charts">
              {chartData.map((item, _idx) => {
                return (
                  <LineChart key={_idx} data={item} />
                )
              })}

            </div>
          </div>
          <div className="listing-wrapper">
            <h2>Top Performing Listing</h2>
            <div className="listing-content-wrapper">
              {topPerforming.map((item, _idx) => {
                return (
                  <Listing key={_idx}
                    image={item.property.thumbnail}
                    title={item.property.title}
                    price="1"
                    slug={item.property.slug}
                    views={item.views}
                    saves={item.saves}
                    leades={item.leads} />

                )
              })}
            </div>
          </div>

        </div>
      </div>
    </DefaultLayout>
  )
}