import axios from 'axios';
import { signInWithPopup } from "firebase/auth";
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Isvg from 'react-inlinesvg';
import { Link, Navigate } from 'react-router-dom';
import { CarouselSlider } from '../components/carousel';
import { EmailInput } from '../components/inputs/email';
import { Password } from '../components/inputs/password';
import { HOST_NAME } from '../config';
import { auth, googleProvider } from '../firebaseCnf';
import { useToken } from '../hooks/auth/useToken';
import { useUser } from '../hooks/auth/useUser';
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_CAPTCHA_SITE_KEY } from '../config';
import Slider1 from '../images/slider-1.png';
import Slider2 from '../images/slider-2.png';
import Slider3 from '../images/slider-3.png';
import Slider4 from '../images/slider-4.png';
import Slider5 from '../images/slider-5.png';
import Apple from '../images/svg/apple.svg';
import Fb from '../images/svg/fb.svg';
import Google from '../images/svg/google.svg';
import logo from '../images/svg/logo.svg';

const slider = [
  {
    src: Slider1
  },
  {
    src: Slider2
  },
  {
    src: Slider3
  },
  {
    src: Slider4
  },
  {
    src: Slider5
  }
]

export const Login = () => {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState('');
  const [check, setCheck] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [recapcthaToken, setRecapcthaToken] = useState('');
  const recaptcha = useRef(null);
  const [, setToken] = useToken();
  const user = useUser();

  const handleCheck = useCallback(() => {
    setCheck(!check);
  }, [check])

  const handleEmail = useCallback((value) => {
    setEmail(value)
  }, [])
  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email))
      setEmailValid(true);
    else setEmailValid(false);
  }, [email]);

  const handlePassword = useCallback((value) => {
    setPassword(value)
  }, [])

  const handleLogin = useCallback(() => {
    setLoading(true);
    const data = { email, password, token: recapcthaToken };
    axios.post(`${HOST_NAME}user/login`, data)
      .then(res => {
        setToken(res.data.token);
        window.location.reload();
      }).catch(err => {
        if (err.response.status === 401) setError(err.response.data.error);
        else setError('Something went wrong. Please try again later');
      }).finally(() => {
        setLoading(false);
        recaptcha.current.reset();
      });
  }, [email, password, setToken, recaptcha, recapcthaToken]);

  const loginWithGoogle = useCallback(() => {
    setLoading(true);
    signInWithPopup(auth, googleProvider)
      .then(userCred => {
        auth.currentUser.getIdToken(true)
          .then(token => {
            axios.post(`${HOST_NAME}user/loginwith`, { token })
              .then(res => {
                setToken(res.data.token);
                window.location.reload();
              })
              .catch(err => console.log(err))
              .finally(() => setLoading(false));
          })
          .catch(err => {
            console.log(err);
            setLoading(false);
          });
      }).catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, [setToken]);

  if (user) return <Navigate to="/dashboard" />
  return (
    <div className="login-wrapper">
      <div className="left">
        <CarouselSlider slider={slider} />
      </div>
      <div className="right">
        <div className="header">
          <div><h1>Log In to</h1> <Isvg src={logo} /> </div>
          <p>Welcome! Get started with hellohere by entering
            Email & Password</p>
          <div className="form">
            <EmailInput setEmail={handleEmail} email={email} label="Email Address" />
            <Password setPassword={handlePassword} password={password} />
            <ReCAPTCHA ref={recaptcha} sitekey={GOOGLE_CAPTCHA_SITE_KEY} onChange={setRecapcthaToken} style={{ marginBottom: "0.75rem" }} />
            <div className="remember">
              <div className="checkbox-wrapper">
                <div className="checkbox" onClick={handleCheck} style={{ display: "inline-box" }}>
                  {check && <div className="checked"></div>}
                </div>
                <p>Remember me</p>
              </div>
              <Link to="/forgot-password">Forgot your password?</Link>
            </div>
            {error && <div className='error'>{error}</div>}
            <button className="login" disabled={!emailValid || loading || recapcthaToken.length === 0} onClick={handleLogin}>{loading ? "PLEASE WAIT..." : "LOGIN"}</button>
            <div className="dont-account">
              <h6> Don’t have an account? <Link to="/sign-up">Register</Link></h6>
            </div>
            <div className="another-options">
              <h4>or use one of these options</h4>
              <div className="button-warpper">
                <button><Isvg src={Fb} /></button>
                <button onClick={loginWithGoogle}> <Isvg src={Google} /> </button>
                <button> <Isvg src={Apple} /> </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}