import LoadingBar from 'apps/dashboard/src/components/LoadingBar';
import { HOST_NAME } from 'apps/dashboard/src/config';
import { useUser } from 'apps/dashboard/src/hooks/auth/useUser';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Isvg from 'react-inlinesvg';
import Close from '../../../images/svg/closeModal.svg';
import { useBillingModal } from '../Provider';
import { Row } from './Row';

const biling = [
  {
    id: '01',
    plan: 'Hello:magic plan',
    price: '162.24',
    paymentStatus: 'Success'
  },
  {
    id: '02',
    plan: 'Hello:magic plan',
    price: '162.24',
    paymentStatus: 'Decline'
  },
  {
    id: '03',
    plan: 'Hello:magic plan',
    price: '162.24',
    paymentStatus: 'Success'
  }
]

export const BillingModal = () => {
  const { closeModal } = useBillingModal();
  const [invoices, setInvoices] = useState(null);
  const user = useUser();

  useEffect(() => {
    if(!user) return;

    axios.get(`${HOST_NAME}subscription/invoices?email=${user.email}`).then(res => {
      console.log('This is the response', res.data)
      setInvoices([]);
      setInvoices(res.data)
    });
  }, [user])

  return (
    <div className="billing-modal-wrapper">
      <div className="header">
        <div className="title">Billing</div>
        <div className="close" onClick={closeModal}>
          <Isvg src={Close} />
        </div>
      </div>
       <div className="table-wrapper">
        <div className="table">
          <div className="table-header">
            <div>Serial No.</div>
            <div>Payment status</div>
            <div>Purpose of payment</div>
            <div>Total</div>
          </div>
          {!invoices 
            ? <LoadingBar/>
            : <div className="content-wrapper">
                <div className="content">
                  {invoices.map((item, _idx) => <Row item={item} key={item.id} />)}
                </div>
              </div>}
        </div>
      </div>
    </div>
  );
};
