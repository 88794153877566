import React, { useCallback } from 'react'

export const Toggle = ({ label, name, checked, handleToggleButtons, dashboard, disabled }) => {

  const handleToggle = useCallback((e) => {
    handleToggleButtons(e)
  }, [handleToggleButtons])

  return (
    <div className="custom-toggle-button">
      {label && <label>{label}</label>}
      <label className="switch">
        <input type="checkbox" name={name} onChange={handleToggle} checked={checked} disabled={disabled} />
        <span className="slider round"> {dashboard && <p>In</p>}</span>
      </label>
    </div>
  )
}