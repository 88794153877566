import { useCallback, useEffect, useState } from 'react';
import Isvg from 'react-inlinesvg';
import Check from '../../../../images/svg/tick-circle-white.svg';
import { loadStripe } from '@stripe/stripe-js';
import { HOST_NAME } from 'apps/dashboard/src/config';
import axios from 'axios';
import moment from 'moment';
import { useMagicSubscriptions, useSubscriptions } from '../useSubscriptionConfig';

export const Package = ({ item, user }) => {
  const [plan, setPlan] = useState('monthly');
  const [activeSubscription, setActiveSubscription] = useState({});
  const subscriptions = useSubscriptions();
  const maigSubscriptions = useMagicSubscriptions();

  const handlePlan = useCallback((value) => {
    setPlan(value)
  }, [])
  
  useEffect(() => {
    if(user.customer_id) {
      user.subscriptions.forEach((sub) => { 
        if(sub.price_id === item.price_id['monthly']) {
          setActiveSubscription({'monthly': {...sub}})
          return;
        }
        
        if(sub.price_id === item.price_id['yearly']) {
          setActiveSubscription({'yearly': {...sub}})
          return;
        }
      })
    }
  }, [item, plan, user])

  const handleSubscribe = async () => {
    const stripe = await loadStripe('pk_live_51LrlVmHfaSP6XcAkfOgZMfJX3Cpyybh5YuItEZ9XfIJShGM7RjxBcNSPYqnwh2PcaqmCE8eEjpr8JJi9nMnGFEjO005435Ib20')  

    if(!activeSubscription[plan] && user.subscriptions.length > 0) {
      if(item.type === 'magic') {
        const userSubscription = user.subscriptions.find((sub) => maigSubscriptions.find((e) => e.product_id === sub.product_id));
        if(userSubscription) {
          await axios.get(`${HOST_NAME}subscription/update-subscription?price_id=${item.price_id[plan]}&subscription_id=${userSubscription.subscription_id}`);
          alert('Subscription updated successfully');
          return;
        }
      }
      
      if(item.type === 'listings') {
        const userSubscription = user.subscriptions.find((sub) => subscriptions.find((e) => e.product_id === sub.product_id));
        if(userSubscription) {
          await axios.get(`${HOST_NAME}subscription/update-subscription?price_id=${item.price_id[plan]}&subscription_id=${userSubscription.subscription_id}`);
          alert('Subscription updated successfully');
          window.location.reload();
          return;
        }
      }
  }

    if(!activeSubscription['monthly'] && !activeSubscription['yearly']) {
      const sessionID = await axios.get(`${HOST_NAME}subscription/create-session?email=${user.email}&price_id=${item.price_id[plan]}`);
  
      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionID.data.sessionID,
      })
  
      return;
    }

    if(activeSubscription[plan] && activeSubscription[plan].cancel_at_period_end) {
      const response = await axios.get(`${HOST_NAME}subscription/reactivate-subscription?email=${user.email}&price_id=${item.price_id[plan]}&subscription_id=${activeSubscription[plan].subscription_id}`);
      
      if(response.status === 200) {
        alert('Subscription reactivated successfully');
        window.location.reload();
      } else {
        alert('Something went wrong, please try again later')
      }

      return;
    }
  }

  const handleUnsubscribe = async () => {
    const result = await axios.get(`${HOST_NAME}subscription/unsubscribe?email=${user.email}&price_id=${item.price_id[plan]}`);

    if(result.status === 200) {
      alert('Subscription cancelled successfully');
      window.location.reload(true);
      window.location.reload(true);
    }
  }


  const handleButton = async () => {
    if(activeSubscription[plan] && !activeSubscription[plan].cancel_at_period_end) {
      handleUnsubscribe();
      return;
    } 

    handleSubscribe();
  }

  return (
    <div className="package-wrapper">
      {!item.free && <div className="plan">
        <button className={plan === 'monthly' ? 'active' : ''} onClick={() => handlePlan('monthly')}>Monthly</button>
        <button className={plan === 'yearly' ? 'active' : ''} onClick={() => handlePlan('yearly')}>Yearly</button>
      </div>}
      {item.logo && <div className='logo'> <Isvg src={item.logo} /> </div>}
      <div className="title">{item.title}</div>
      <div className="desc">{item.description}</div>
      <div className="price">
        <h3>{item.price[plan]} </h3>
        <span>Per <br />
          {plan === 'monthly'? 'month': 'year'}</span>
      </div>
      <div className='subscribe-link'>
        {item.free ? <div></div> : item.type==='listings'?<button>Coming Soon</button> : <button onClick={handleButton} className={`${activeSubscription[plan] ? 'active' : ''}`}>{activeSubscription[plan] ? activeSubscription[plan].cancel_at_period_end ? 'Renew': 'Cancel Plan' : 'Subscribe'}</button>}
      </div>
      {(activeSubscription[plan] != null && activeSubscription[plan].cancel_at_period_end) && <div className="desc">Active until {moment(activeSubscription[plan].period_end).format('DD.MM.yyyy')}<br/><br/></div>}
      <div className="includes">
        <div className="title-includes">This includes :</div>
        {item.includes.map((option, _idx) => <div key={_idx} className="dot"><Isvg src={Check} /><h6>{option}</h6></div>)}

      </div>
    </div>
  )
}
