import axios from 'axios';
import { useCallback, useEffect, useState } from "react";
import Isvg from 'react-inlinesvg';
import { HOST_NAME } from '../../../config';
import { useToken } from '../../../hooks/auth/useToken';
import Lock from '../../../images/svg/lock-bright.svg';
import { PhoneSelect } from "../../phone-select";


export const ContactInfo = ({ current, email, premium }) => {
  const [phone, setPhone] = useState(current.phones[0] || '');
  const [secondPhone, setSecondPhone] = useState(current.phones[1] || '');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token] = useToken();

  const [formValue, setFormValue] = useState({
    email: email,
    secondemail: current.secondary_email,
    website: current.website,
    facebook: current.facebook,
    instagram: current.instagram,
  });

  const handleError = useCallback(() => {
    setError(prevState => !prevState);
  }, [])

  const phoneSelectHandler = useCallback((value) => {
    setPhone(value)
  }, [])

  const secondPhoneSelectHandler = useCallback((value) => {
    setSecondPhone(value)
  }, [])

  const handleChange = useCallback((e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value })
  }, [formValue])

  const handleEnableForm = useCallback(() => {
    alert("We need to handle how to make user from free to premium");
  }, [])

  const handleSubmit = useCallback(() => {
    if (error) return;
    setLoading(true);
    const config = {
      method: 'post',
      url: `${HOST_NAME}user/update`,
      headers: {
        'Authorization': 'Bearer ' + token,
      },
      data: {
        type: "contact",
        secondary_email: formValue.secondemail,
        website: formValue.website,
        facebook: formValue.facebook,
        instagram: formValue.instagram,
        phones: [phone, secondPhone]
      }
    };
    axios(config)
      .then(res => console.log("Updated successfully"))
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, [error, formValue, phone, secondPhone, token]);

  return (
    <div className="account-settings-wrapper">
      <div className="content contact-info">
        <h4>Contact information</h4>
        <div className="edit-company-info">
          {!premium &&
            <div className="label">
              <label>Email</label>
              <div className="upgrade">
                <p>Website and social media URL  is not available in your account</p>
                <button className="black-button" onClick={handleEnableForm}>Upgrade</button>
              </div>
            </div>
          }
          <div className="input">
            <input type="text" name="email" value={formValue.email} onChange={handleChange} />
          </div>
          <div className="input">
            <label>Secondary leads email</label>
            <input type="text" name="secondemail" value={formValue.secondemail} onChange={handleChange} />
          </div>
          <div className="input mb-1">
            <label>Phone 1</label>
            <PhoneSelect phoneHandler={phoneSelectHandler} name="areaCode" textName="phone" textValue={phone ?? {}} handleError={handleError} />
          </div>
          <div className="input mb-1">
            <label>Phone 2</label>
            <PhoneSelect phoneHandler={secondPhoneSelectHandler} name="areaCode" textName="secondPhone" textValue={secondPhone ?? {}} handleError={handleError} />
          </div>
          {/* <div className="input mb-1">
            <label>Phone 2</label>
            <PhoneSelect phoneHandler={secondPhoneSelectHandler} name="areaCode" textName="secondPhone" textValue={secondPhone ?? {}} handleError={handleError} />
          </div> */}
          <div className="input">
            <label>Website URL <Isvg src={Lock} /></label>
            <input type="text" disabled={!premium} name="website" value={formValue.website} onChange={handleChange} />
          </div>
          <div className="input">
            <label>Facebook URL <Isvg src={Lock} /></label>
            <input type="text" disabled={!premium} name="facebook" value={formValue.facebook} onChange={handleChange} />
          </div>
          <div className="input">
            <label>Instagram URL <Isvg src={Lock} /></label>
            <input type="text" disabled={!premium} name="instagram" value={formValue.instagram} onChange={handleChange} />
          </div>
          <button disabled={loading || error} onClick={handleSubmit}>{loading ? "SAVING..." : "SAVE"}</button>
        </div>

      </div>
    </div>
  )
}