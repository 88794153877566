import React, { useEffect, useRef } from "react";
import classes from './GmapLocationPicker.module.css';

const GmapLocationPicker = ({ lat, lng, close, updateLoc }) => {
    const mapContainer = useRef();
    const searchInput = useRef();
    const marker = useRef();

    useEffect(() => {
        // initialize map
        const map = new window.google.maps.Map(mapContainer.current, {
            zoom: 10,
            center: { lat: lat, lng: lng },
        });
        map.addListener('click', e => {
            if (marker.current && marker.current.setMap) marker.current.setMap(null);
            marker.current = new window.google.maps.Marker({ map, position: e.latLng });
        });

        // search box
        const searchBox = new window.google.maps.places.SearchBox(searchInput.current);
        map.addListener("bounds_changed", () => searchBox.setBounds(map.getBounds()));

        // when user click suggestion
        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();
            if (places.length === 0) return;

            const bounds = new window.google.maps.LatLngBounds();
            places.forEach(place => {
                if (marker.current && marker.current.setMap) marker.current.setMap(null);
                if (!place.geometry || !place.geometry.location) return;

                // add marker
                marker.current = new window.google.maps.Marker({ map, position: place.geometry.location });

                // check bounds
                if (place.geometry.viewport) bounds.union(place.geometry.viewport);
                else bounds.extend(place.geometry.location);
            });
            map.fitBounds(bounds);
        });
    }, [lat, lng]);

    const onSelectClicked = () => {
        if (!marker.current) return close();
        console.log("Selected location:", marker.current.position.lat(), marker.current.position.lng());
        updateLoc(marker.current.position.lat(), marker.current.position.lng());
    }

    return (
        <div className={classes['location-picker__container']}>
            <div className={classes['location-picker__gui']}>
                <div className={classes['location-picker__ctrl']}>
                    <input ref={searchInput} type="text" style={{ width: "60%" }} />
                    <button type="button" onClick={onSelectClicked}>Select</button>
                    <button type="button" onClick={close}>Close</button>
                </div>
                <div className={classes['location-picker__map']} ref={mapContainer} />
            </div>
        </div>
    )
}

export default GmapLocationPicker;