import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Spinner } from '../Modal';

const SpinnerModalContext = createContext();

export const SpinnerModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback((value) => {
    setIsOpen(value);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const value = useMemo(() => ({
    isOpen,
    openModal,
    closeModal
  }), [closeModal, isOpen, openModal]);

  return (
    <SpinnerModalContext.Provider value={value}>
      {children}
      {isOpen && <Spinner />}
    </SpinnerModalContext.Provider>
  )
}

export const useSpinnerModal = () => useContext(SpinnerModalContext)