import React, { useCallback, useEffect, useState, useRef } from 'react';
import Isvg from 'react-inlinesvg';
import { Link, useParams } from 'react-router-dom';
import { CarouselSlider } from '../components/carousel';
import { EmailInput } from '../components/inputs/email';
import { Password } from '../components/inputs/password';
import { Input } from '../components/inputs/text';
import Globe from '../images/svg/globe.svg';
import logo from '../images/svg/logo.svg';
import Slider1 from '../images/slider-1.png';
import Slider2 from '../images/slider-2.png';
import Slider3 from '../images/slider-3.png';
import Slider4 from '../images/slider-4.png';
import Slider5 from '../images/slider-5.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { HOST_NAME, GOOGLE_CAPTCHA_SITE_KEY } from '../config';
import ReCAPTCHA from "react-google-recaptcha";
import { LocationInput } from '../components/inputs/location';

const slider = [
  {
    src: Slider1
  },
  {
    src: Slider2
  },
  {
    src: Slider3
  },
  {
    src: Slider4
  },
  {
    src: Slider5
  }
]

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailRegex.test(email)) return true;
  return false;
}

export const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [location, setLocation] = useState('');
  const [recapcthaToken, setRecapcthaToken] = useState('');
  const recaptcha = useRef(null);
  const [url, setUrl] = useState('');
  const { url: pasedUrl } = useParams();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleConfirmPassword = useCallback((value) => {
    setConfirmPassword(value)
  }, [])

  const handleEmail = useCallback((value) => {
    setEmail(value)
  }, [])

  const handlePassword = useCallback((value) => {
    setPassword(value)
  }, [])

  const handleUrl = useCallback((value) => {
    setUrl(value);
  }, [])

  const handleLocation = useCallback((value) => {
    setLocation(value)
  }, [])

  useEffect(() => {
    if (pasedUrl) {
      setUrl(pasedUrl)
    }
  }, [pasedUrl])

  const handleSubmit = useCallback(() => {
    setLoading(true);
    setError("");

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }
    
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      setLoading(false);
      return;
    }

    if(location === '') {
      setError("Please enter a valid location");
      setLoading(false);
      return;
    }

    // Submit
    const data = { email, password, confirm: confirmPassword, website: url, token: recapcthaToken, location: location };
    axios.post(`${HOST_NAME}user/signup`, data)
      .then(res => {
        if (res.status === 201) {
          alert("we have sent you an email to verify your account");
          navigate("/login");
        }
        else setError(res.data);
      })
      .catch(err => setError(err.response.data.error))
      .finally(() => {
        setLoading(false);
        recaptcha.current.reset();
      });
  }, [password, confirmPassword, email, url, navigate, recapcthaToken, recaptcha]);

  return (
    <div className="signup-wrapper">
      <div className="left">
        <CarouselSlider slider={slider} />
      </div>
      <div className="right">
        <div className="header">
          <div><h1>Sign up to</h1> <Isvg src={logo} /> </div>
          <p>Welcome! Get started with hellohere by entering
            Email & Password</p>
          <div className="form">
            <EmailInput setEmail={handleEmail} value={email} />
            <Input handleValue={handleUrl} desc="We consent to hello here is linking our properties directly from our website to Hello Here's listings." info="Our new technology only needs your web url and your properties will be live in the next 36 hours. Updates happen once a day. It's that easy!" name="url" svg={Globe} value={url} />
            <LocationInput setLocation={handleLocation} value={location}></LocationInput>
            <Password setPassword={handlePassword} password={password} label="Password a secure password" />
            <Password setPassword={handleConfirmPassword} password={confirmPassword} label="Confirm your password" />
            
            <ReCAPTCHA ref={recaptcha} sitekey={GOOGLE_CAPTCHA_SITE_KEY} onChange={setRecapcthaToken} />
            {error && <div className="error" style={{ color: "red" }}>{error}</div>}
            <button className="login" disabled={loading} onClick={handleSubmit}>{loading ? "PLEASE WAIT..." : "SIGNUP"}</button>
            <div className="dont-account">
              <h6>Already have an account? <Link to="/login">Login</Link></h6>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}