import { useMemo } from 'react';
import Face from '../../images/svg/social/face.svg';
import Insta from '../../images/svg/social/insta.svg';
import TikTok from '../../images/svg/social/tik.svg';

export const useAccountSettings = () => useMemo(() => ({
    folowersMM: [
        { _id: 1, svg: Insta, text: "followers on instagram", numbers: "156.000+", border: "#7232BD", color: "#7232BD" },
        { _id: 1, svg: Face, text: "followers on facebook", numbers: "410.000+", border: "#1877F2", color: "#1877F2" },
        { _id: 3, svg: TikTok, text: "followers on tiktok", numbers: "31.000+", border: "#010101", color: "#010101" }
    ],
    folowersLS: [ { _id: 1, svg: Insta, text: "followers on instagram", numbers: "185.000+", border: "#7232BD", color: "#7232BD" }],
    folowersHH: [ { _id: 1, svg: Insta, text: "Shoppable bio-link tool with city guides. (micro-websites)", } ]
}) ,[])
