import React from "react";

export const Input = ({ label, value, handleOnChange, placeholder, name, requiredError, requiredFields, validateValue }) => {

  return (
    <>
      <div className="input">
        {label && <label>{label}</label>}
        <input type="text" placeholder={placeholder} name={name} value={value} onChange={(e) => handleOnChange(e)} />
        {requiredError && requiredFields && !validateValue &&
          <div className="error">
            this field is required!
          </div>
        }
      </div>

    </>
  )
}