import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import Isvg from 'react-inlinesvg';
import { Link, useNavigate } from 'react-router-dom';
import { Select } from '../../components/select';
import { HOST_NAME } from '../../config';
import ListingPreview from '../../images/listing-preview.png';
import SearchOutlined from '../../images/svg/ant-design_search-outlined.svg';
import Checked from '../../images/svg/checked.svg';
import WhiteEdit from '../../images/svg/edit-white.svg';
import Edit from '../../images/svg/edit.svg';
import Rocket from '../../images/svg/ion_rocket-outline.svg';
import GoogleMapsIcon from '../../images/svg/logos_google-maps.svg';
import WhiteRocket from '../../images/svg/rocket-white.svg';
import Save from '../../images/svg/save.svg';
import WhiteStatistic from '../../images/svg/statistic-white.svg';
import StatisticIcon from '../../images/svg/statistic.svg';
import Trash from '../../images/svg/trash.svg';
import { useSpinnerModal } from '../../modals/Spinner/Provider';
import { useOnClickOutside } from '../../utils/useOnClickOutside';
import { DaDropZone } from '../Dropzone';
import GmapLocationPicker from '../GmapLocationPicker';
import { Toggle } from '../inputs/toggle';
import { ListPreview } from '../list-preview';
import { Input } from './Input';

const listingSelection = [
  {
    _id: 1,
    text: "to buy",
    value: "sale"
  },
  {
    _id: 2,
    text: "to rent",
    value: "rent"
  }
]

const PropertyTypes = [
  {
    _id: 1,
    text: "Apartment",
    value: "apartment"
  },
  {
    _id: 2,
    text: "House",
    value: "house"
  },
  {
    _id: 3,
    text: "Villa",
    value: "villa"
  },
  {
    _id: 4,
    text: "Finca",
    value: "finca"
  },
  {
    _id: 5,
    text: "Plot",
    value: "plot"
  },
  {
    _id: 5,
    text: "Commercial",
    value: "commercial"
  }
]

const Currencies = [
  {
    _id: 1,
    text: "EUR",
    value: "€"
  },
  {
    _id: 2,
    text: "USD",
    value: "$"
  }
]

const Prices = [
  {
    _id: 1,
    text: "Show price",
    value: "show"
  },
  {
    _id: 2,
    text: "Hide price",
    value: "hide"
  }
]

const UnitTypes = [
  {
    _id: 1,
    text: "m2",
    value: "m2"
  },
  {
    _id: 2,
    text: "f2",
    value: "f2"
  }
]

const options = [
  {
    _id: 1,
    option: "Panoramic / Scenic View"
  },
  {
    _id: 2,
    option: "Mountain View"
  },
  {
    _id: 3,
    option: "Water View"
  },
  {
    _id: 3,
    option: "Ocean View"
  },
  {
    _id: 4,
    option: "Sea View"
  },
  {
    _id: 5,
    option: "Lake View"
  },
  {
    _id: 6,
    option: "River View"
  }
]

const capitalizeFirstLetter = word => {
  if (word) return word.charAt(0).toUpperCase() + word.slice(1);
  return word;
}

const requiredFields = {
  category: true,
  listingPreview: false,
  listingPreviewFile: false,
  office: false,
  propertyType: true,
  price: true,
  priceCurrency: true,
  showPrice: true,
  title: true,
  description: true,
  address: false,
  zipCode: false,
  city: false,
  licenseNumber: false,
  preOwned: false,
  builtYear: false,
  bedrooms: true,
  bathrooms: true,
  floors: false,
  livingArea: true,
  landArea: true,
  livingAreaM2: false,
  landAreaM2: false,
  view: false,
  outdoor: false,
  indoor: false,
  landfeatures: false,
  youtube: false,
  virtualTour: false,
  selectedView: false,
  photos: true,
  onOff: false,
  productCatalog: false,
  guideTree: false,
  longtitude: false,
  latitude: false,
  googleMaps: false,
}
const checkRequiredFields = (formValues) => {
  for (const field in requiredFields) {
    if (requiredFields[field] && !formValues[field]) {
      return true;
    }
  }
  return false;
}

export const AddOrCreate = ({ data, type, id, previewData: previewDataRename, edit }) => {
  const { openModal } = useSpinnerModal();
  const [formValues, setFormValues] = useState({
    category: "",
    listingPreview: null,
    listingPreviewFile: null,
    office: "",
    propertyType: "",
    price: "",
    priceCurrency: "",
    showPrice: "",
    title: "",
    description: "",
    address: "",
    zipCode: "",
    city: "",
    licenseNumber: "",
    preOwned: "",
    builtYear: "",
    bedrooms: "",
    bathrooms: "",
    floors: "",
    livingArea: "",
    landArea: "",
    livingAreaM2: "",
    landAreaM2: "",
    view: "",
    outdoor: "",
    indoor: "",
    landfeatures: "",
    youtube: "",
    virtualTour: "",
    selectedView: [],
    photos: [],
    onOff: true,
    productCatalog: false,
    guideTree: false,
    longtitude: 3.02,
    latitude: 39.7,
    googleMaps: ""
  });
  const [requiredError, setRequiredError] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [toggleViewSelect, setToggleViewSelect] = useState(false)
  const result = useRef(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setFormValues({ ...data })
    }
  }, [data])

  const handleSelectedView = useCallback((value) => {

    if (!formValues.selectedView.includes(value)) {
      setFormValues({ ...formValues, selectedView: [...formValues.selectedView, value] })
    }
    if (formValues.selectedView.includes(value)) {
      const copyArray = [...formValues.selectedView];
      const index = copyArray.indexOf(value)
      copyArray.splice(index, 1);
      setFormValues({ ...formValues, selectedView: copyArray })
    }

  }, [formValues])

  const handleToggleViewSelect = useCallback(() => {
    setToggleViewSelect(!toggleViewSelect)
  }, [toggleViewSelect])


  const handleInputs = useCallback((e) => {
    if (!e.target) {
      setFormValues({ ...formValues, [e.name]: e[e.name] });
      return;
    }
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }, [formValues])

  const handleSelectFile = useCallback((e) => {
    const photos = [...formValues.photos];
    if (photos.length <= 0) {
      photos.push(e.target.files[0]);

    } else { photos[0] = e.target.files[0]; }

    setFormValues({ ...formValues, listingPreview: URL.createObjectURL(e.target.files[0]), listingPreviewFile: e.target.files[0], photos });
  }, [formValues])

  const handleSelectPhotos = useCallback((imageFiles) => {
    const oldFormValues = { ...formValues };
    oldFormValues.photos = imageFiles;
    oldFormValues.listingPreview = oldFormValues.listingPreviewFile = null;
    if (imageFiles.length > 0) {
      oldFormValues.listingPreview = URL.createObjectURL(imageFiles[0]);
      oldFormValues.listingPreviewFile = imageFiles[0];
    }
    setFormValues(oldFormValues);
  }, [formValues])

  const handleToggleButtons = useCallback((e) => {
    if (e.target.name === "onOff")
      setFormValues({ ...formValues, [e.target.name]: e.target.checked });
    else alert("Coming soon...");
  }, [formValues])

  const handleLatLngChange = (lat, lng) => {
    setFormValues({ ...formValues, latitude: lat, longtitude: lng });
    setShowMap(false);
  }

  const validatePrice = () => {
    const tiles = formValues["price"].split("€")

    for (let i = 0; i < tiles.length; i++) {
      if (!isNaN(tiles[i])) return true;
    }

    return false;
  }

  const handleSave = useCallback(() => {
    if (!validatePrice()) {
      alert("Price is not a valid number")
      return;
    }

    setRequiredError(false);
    if (checkRequiredFields(formValues)) {
      setRequiredError(true);
      alert("Please fill all the required fields");
      return;
    }



    setLoading(true);
    const createPropertyListing = async () => {
      // save all images to server
      const imagesPromise = []
      if (formValues.listingPreviewFile) {
        const previewData = new FormData();
        previewData.append('image', formValues.listingPreviewFile);
        imagesPromise.push(axios.post(`${HOST_NAME}upload/images`, previewData));
      }
      const images = await Promise.all(imagesPromise);

      // save data to server
      const config = {
        method: edit ? 'patch' : 'post',
        url: `${HOST_NAME}properties`,
        data: {
          ...formValues,
          thumbnail: images.map(img => img.data.url),
          blobs: images.map(img => img.data.blob),
          slug: id
        }
      };

      axios(config)
        .then(res => {
          if (res.status === 201 || res.status === 200) {
            navigate("/dashboard-listing");
          } else {
            alert("request failed with status: " + res.status);
          }
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    };

    createPropertyListing();
  }, [formValues, navigate, edit, id]);

  const handleDelete = useCallback(() => {
    setLoading(true)
    axios.delete(`${HOST_NAME}properties/${id}`)
      .then(res => {
        if (res.status === 200) {
          navigate("/dashboard-listing");
        }
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, [id, navigate]);

  useEffect(() => {
    openModal(loading);
  }, [loading, openModal])


  useOnClickOutside(result, () => setToggleViewSelect(false))

  return (
    <div>
      {edit && <div>
        <ListPreview listing={previewDataRename}>
          <div className="buttons">
            <button onClick={handleDelete}> <Isvg src={Trash} /> Delete</button>
            <button onClick={handleSave}> <Isvg src={Save} />Save Changes</button>
          </div>
        </ListPreview>
        <div className="tabs">
          <Link to={`/list-statistic/statistic/${id}`} className={type === "statistic" ? "active" : ""}><button > {type === "statistic" ? <Isvg src={WhiteStatistic} /> : <Isvg src={StatisticIcon} />}  STATISTICS</button></Link>
          <Link to={`/list-statistic/edit/${id}`} className={type === "edit" ? "active" : ""}><button > {type === "edit" ? <Isvg src={WhiteEdit} /> : <Isvg src={Edit} />} Edit listing</button></Link>
          <Link to={`/list-statistic/promote/${id}`} className={type === "promote" ? "active" : ""}><button> {type === "promote" ? <Isvg src={WhiteRocket} /> : <Isvg src={Rocket} />} Promote</button></Link>
        </div>

      </div>}
      <div className="edit-listing">
        {showMap && <GmapLocationPicker lat={formValues.latitude} lng={formValues.longtitude} close={() => setShowMap(false)} updateLoc={handleLatLngChange} />}
        <div className="img-wrapper">

          <img src={formValues.listingPreview ?? ListingPreview} alt="Listing" />
          <button> <input type="file" onChange={handleSelectFile} /> Change Media</button>

        </div>
        <div className="edit-form-wrapper">
          <div className="listing-edit-form">
            <div className="form">
              <h1>Create new listing</h1>
              <p>Please provide information about the listing in the following form. You can save it as a draft and came back to it later.</p>
              <div className="wrapper-listing-section">
                <div className="input">
                  <Select
                    data={listingSelection}
                    name="category"
                    placeholder={formValues.category || "Select type"}
                    selectValueHandler={handleInputs}
                    requiredError={requiredError}
                    requiredFields={requiredFields['category']}
                    validateValue={formValues.category}
                  />

                </div>
                <Toggle label="on/off" name="onOff" checked={formValues.onOff} handleToggleButtons={handleToggleButtons} />
              </div>
            </div>
            <div className="form">
              <div className="wrapper-title">
                <h1>General Information</h1>
                <div>
                  <Toggle label="Facebook/Instagram Product Catalog" name="productCatalog" checked={formValues.productCatalog} handleToggleButtons={handleToggleButtons} />
                  <Toggle label="Guide tree" name="guideTree" checked={formValues.guideTree} handleToggleButtons={handleToggleButtons} />
                </div>
              </div>

              <div className="input">
                <label>Property type</label>
                <Select
                  data={PropertyTypes}
                  placeholder={capitalizeFirstLetter(formValues.propertyType) || "Select property type"}
                  name="propertyType"
                  selectValueHandler={handleInputs}
                  requiredError={requiredError}
                  requiredFields={requiredFields['propertyType']}
                  validateValue={formValues.propertyType}
                />

              </div>
              <div className="input">
                <label>Price</label>
                <div className="price">
                  <div style={{ marginRight: "1rem" }}>
                    <input type="text" name="price" value={formValues?.price ?? ""} placeholder="€ 5.550.000" onChange={handleInputs} />
                    {
                      requiredError && requiredFields['price'] && !formValues.price &&
                      <div className="error">
                        this field is required!
                      </div>
                    }
                  </div>
                  <Select
                    data={Currencies}
                    placeholder={formValues.priceCurrency || "Currency"}
                    name="priceCurrency"
                    selectValueHandler={handleInputs}
                    requiredError={requiredError}
                    requiredFields={requiredFields['priceCurrency']}
                    validateValue={formValues.priceCurrency}
                  />
                </div>
              </div>
              <div className="input">
                <label>Price Display</label>
                <Select
                  data={Prices}
                  placeholder={formValues.showPrice || "Select price show/hide"}
                  name="showPrice"
                  selectValueHandler={handleInputs}
                  requiredError={requiredError}
                  requiredFields={requiredFields.showPrice}
                  validateValue={formValues.showPrice}
                />
              </div>

              <div className="headline">
                <Input
                  label="Headline"
                  placeholder="Modern villa with breathtaking sea view"
                  name="title"
                  value={formValues?.title ?? ""}
                  handleOnChange={handleInputs}
                  requiredError={requiredError}
                  requiredFields={requiredFields.title}
                  validateValue={formValues.title}
                />
                <div className="lenght"> {formValues?.title?.length ?? 0}/100 </div>
              </div>

              <div className="input textarea">
                <label>Description</label>
                <textarea placeholder="please describe the property in detail" onChange={handleInputs} name="description" value={formValues?.description ?? ""}></textarea>
                {
                  requiredError && requiredFields.description && !formValues.description &&
                  <div className="error">
                    this field is required!
                  </div>
                }
              </div>

            </div>

            <div className="form">
              <h1>Address</h1>
              <div className="input-wrapper">
                <Input
                  label="Address"
                  placeholder="Carrer d’Antoni Maria Alcover"
                  name="address"
                  value={formValues?.address ?? ""}
                  handleOnChange={handleInputs}
                />

                <Input
                  label="Zip code"
                  placeholder="0718"
                  name="zipCode"
                  value={formValues?.zipCode ?? ""}
                  handleOnChange={handleInputs}
                />

              </div>


              <Input
                label="City"
                placeholder="Portals Nous"
                name="city"
                value={formValues?.city ?? ""}
                handleOnChange={handleInputs}
              />


              <div className="input-wrapper">
                <Input
                  label="Longtitude"
                  placeholder="23.01234567"
                  name="longtitude"
                  value={formValues?.longtitude ?? ""}
                  handleOnChange={handleInputs}
                />

                <Input
                  label="Latitude"
                  placeholder="90.01234567"
                  name="latitude"
                  value={formValues?.latitude ?? ""}
                  handleOnChange={handleInputs}
                />
              </div>

              <div className="google-maps-search">
                <label>Google maps</label>
                <div className="input-wrap">
                  <Isvg src={GoogleMapsIcon} />
                  <input type="text" placeholder="Search on google maps" onClick={() => setShowMap(true)} />
                  <Isvg src={SearchOutlined} />
                </div>
              </div>

            </div>

            <div className="form">
              <h1>Intern Property Number</h1>
              <Input
                label="Property Number"
                placeholder="123467897"
                name="propertyNumber"
                value={formValues?.licenseNumber ?? ""}
                handleOnChange={handleInputs}
              />
            </div>

            <div className="form">
              <h1>Parameters</h1>
              <div className="input-wrapper">

                <Input
                  label="Pre-owned or New"
                  placeholder="New"
                  name="preOwned"
                  value={formValues?.preOwned ?? ""}
                  handleOnChange={handleInputs}
                />


                <Input
                  label="Year built"
                  placeholder="2008"
                  name="builtYear"
                  value={formValues?.builtYear ?? ""}
                  handleOnChange={handleInputs}
                />

              </div>
              <div className="input-wrapper">
                <Input
                  label="Bedrooms"
                  placeholder="8"
                  name="bedrooms"
                  value={formValues?.bedrooms ?? ""}
                  handleOnChange={handleInputs}
                  requiredError={requiredError}
                  requiredFields={requiredFields.bedrooms}
                  validateValue={formValues.bedrooms}
                />

                <Input
                  label="Bathrooms"
                  placeholder="8"
                  name="bathrooms"
                  value={formValues?.bathrooms ?? ""}
                  handleOnChange={handleInputs}
                  requiredError={requiredError}
                  requiredFields={requiredFields.bathrooms}
                  validateValue={formValues.bathrooms}
                />
              </div>

              <Input
                label="Floors"
                placeholder="2"
                name="floors"
                value={formValues?.floors ?? ""}
                handleOnChange={handleInputs}
              />


              <div className="input">
                <label>Living area</label>
                <div className="price">
                  <input type="text" name="livingArea" placeholder="920" value={formValues?.livingArea ?? ""} onChange={handleInputs} />
                  <Select
                    data={UnitTypes}
                    placeholder={formValues.landAreaM2 || "m2"}
                    name="livingAreaM2"
                    selectValueHandler={handleInputs}
                    requiredError={requiredError}
                    requiredFields={requiredFields.livingArea}
                    validateValue={formValues.livingArea}
                  />
                </div>
              </div>

              <div className="input">
                <label>Land area</label>
                <div className="price">
                  <input type="text" name="landArea" placeholder="1200" value={formValues?.landArea ?? ""} onChange={handleInputs} />
                  <Select
                    data={UnitTypes}
                    placeholder={formValues.livingAreaM2 || "m2"}
                    name="landAreaM2"
                    selectValueHandler={handleInputs}
                    requiredError={requiredError}
                    requiredFields={requiredFields.landArea}
                    validateValue={formValues.landArea}
                  />
                </div>
              </div>

            </div>

            <div className="form">
              <h1>Features and amenities</h1>
              <p>Select property features to help buyers find your property with filters</p>
              <div className="input">
                <label>View</label>
              </div>
              <div className="select-view" onClick={handleToggleViewSelect} ref={result}>
                <div className="select-view-input">
                  {formValues?.selectedView?.map((item, _idx) => {
                    return (
                      <span key={_idx}>{item}</span>
                    )
                  })}
                </div>
                {toggleViewSelect &&
                  <div className="options">
                    {options.map((item, _idx) => {
                      return (
                        <div key={_idx} onClick={() => handleSelectedView(item.option)}> <div className={`check ${formValues?.selectedView.includes(item.option) ? "checked" : ""} `}> <Isvg src={Checked} /> </div> {item.option} </div>
                      )
                    })}
                  </div>
                }
              </div>

              <Input
                label="Outdoor"
                placeholder="Select outdoor features"
                name="outdoor"
                value={formValues?.outdoor ?? ""}
                handleOnChange={handleInputs}
              />

              <Input
                label="Indoor"
                placeholder="Select indoor features"
                name="indoor"
                value={formValues?.indoor ?? ""}
                handleOnChange={handleInputs}
              />

              <Input
                label="Land features"
                placeholder="Select lot features"
                name="landfeatures"
                value={formValues?.landfeatures ?? ""}
                handleOnChange={handleInputs}
              />

            </div>

            <div className="form seller-info">
              <h1>Photos and media</h1>
              <Input
                label="Video (Youtube)"
                placeholder="https://www.youtube.com/watch?v_uE-1rpdqjay"
                name="youtube"
                value={formValues?.youtube ?? ""}
                handleOnChange={handleInputs}
              />

              <Input
                label="Virtual tour"
                placeholder="https;//my.matterport.com/show/?m=example"
                name="virtualTour"
                value={formValues?.virtualTour ?? ""}
                handleOnChange={handleInputs}
              />
            </div>
            <div className="form">
              <div className="input">
                <label>Photos</label>
              </div>

              <DaDropZone photos={formValues.photos} setPhotos={handleSelectPhotos} />
              {
                requiredError && requiredFields['photos'] && formValues.photos.length === 0 &&
                <div className="error">
                  you need to upload atleast one photo!
                </div>
              }
            </div>
          </div>

          {!data && <button onClick={handleSave} disabled={loading}>{loading ? "Saving..." : "Save Listing"}</button>}
        </div>
      </div>
    </div>
  )
}