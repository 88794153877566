import React from "react";
import Isvg from "react-inlinesvg";

export const Folowers = ({ text, numbers, svg, border, color, last }) => {
    return (
        <div className={`folowers ${last ? "last-f" : ""}`} style={{ borderColor: border }}>
            <Isvg src={svg} />
            <div>
                {!last && <div className="numbers" style={{ color: color }}>{numbers}</div>}
                <div className="text">{text}</div>
            </div>
        </div>
    )
}