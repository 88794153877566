import React, { useEffect } from 'react';
import PrivateRoute from '../hooks/auth/PrivateRoute';
import { useSpinnerModal } from '../modals/Spinner/Provider';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

export const DefaultLayout = ({ children, isPrivate, loading, hideNavbar }) => {
  const { openModal } = useSpinnerModal();
  
  useEffect(() => openModal(loading), [loading, openModal])

  if (isPrivate) {
    return (
      <PrivateRoute>
        <header><Navbar /></header>
        <main>{children}</main>
        <footer><Footer /></footer>
      </PrivateRoute>
    )
  } else {
    return (
      <>
        {!hideNavbar && <header><Navbar /></header>}
        <main>{children}</main>
        <footer><Footer /></footer>
      </>
    )
  }
}
