import axios from 'axios';
import { useEffect, useState } from 'react';
import Isvg from 'react-inlinesvg';
import { ChangePassword } from '../../components/account-forms/change-password';
import { CompanyName } from '../../components/account-forms/company-name';
import { ContactInfo } from '../../components/account-forms/contact-info';
import { DaDropZone } from '../../components/Dropzone';
import LoadingBar from '../../components/LoadingBar';
import { HOST_NAME } from '../../config';
import { DefaultLayout } from '../../containers/DefaultLayout';
import { useToken } from '../../hooks/auth/useToken';
import Check from '../../images/svg/check.svg';
import { Subscription } from './Subscription';

export const AccountSettings = () => {
  const [user, setUser] = useState(null);
  const [isPremium, setIsPremium] = useState(false);
  const [token] = useToken();

  useEffect(() => {
    const config = {
      method: 'get',
      url: `${HOST_NAME}user`,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };
    axios(config)
      .then((res) => setUser(res.data))
      .catch((err) => console.log(err));
  }, [token]);

  useEffect(() => {
    if (user && user.company.account_type === 'PREMIUM') setIsPremium(true);
    else setIsPremium(false);
  }, [user]);

  if (!user) return <LoadingBar />;
  return (
    <DefaultLayout isPrivate={true}>
      <div className="account-settings-wrapper">
        <div className="content">
          <h1>Account Settings</h1>
        </div>
        <div className="acc-type">
          <h3>Account type</h3>
          <div className="check">
            <Isvg src={Check} />
            <h3>{isPremium ? 'PREMIUM' : 'FREE'}</h3>
          </div>
        </div>

        <div className="content">
          {!isPremium && (
            <div className="contact">
              {/* <p>Your account type is Free. If you would like to upgrade your account, please</p>
              <a href="mailto:info@mallorcamagic.es">Contact us</a> */}
              <Subscription/>
            </div>
          )}
          <div className="member">
            <h2>Member since</h2>
            <h3>{new Date(user.createdAt._seconds * 1000).getFullYear() || '-'}</h3>
          </div>
        </div>
      </div>
      <ChangePassword />
      <CompanyName
        current={user.company}
        address={user.address}
        premium={isPremium}
      />
      <ContactInfo
        current={user.contact}
        email={user.email}
        premium={isPremium}
      />
      <CompanyName current={user.company} address={user.address} premium={isPremium} />
      <ContactInfo current={user.contact} email={user.email} premium={isPremium} />


      <div className="account-settings-wrapper">
        <div className="content branding">
          <h4>Branding</h4>
          <div className="input">
            <label>Page cover</label>
            <DaDropZone photos={[]} />
          </div>
          <div className="input">
            <label>Company logo</label>
            <DaDropZone photos={[]} />
          </div>
          <div className="input">
            <label>Listing logo</label>
            <DaDropZone photos={[]} />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
