import { useMemo } from "react";

export const useBasicInfoFormConfig = (handleType, handleLanguage, basicInfo) => useMemo(() => ([
    {
        title: 'Transaction type',
        inputs: [
            { name: 'sale', onChange: handleType, label: 'For Sale', value: basicInfo.transactionType },
            { name: 'rent', onChange: handleType, label: 'For rent', value: basicInfo.transactionType },
            { name: 'holiday', onChange: handleType, label: 'Holiday rental', value: basicInfo.transactionType }
        ]
    },
    {
        title: 'Description language',
        inputs: [
            { name: 'eng', onChange: handleLanguage, label: 'English', value: basicInfo.language },
            { name: 'es', onChange: handleLanguage, label: 'Spanish', value: basicInfo.language },
            { name: 'de', onChange: handleLanguage, label: 'German', value: basicInfo.language },
        ]
    }
]), [basicInfo, handleLanguage, handleType])