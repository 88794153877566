import { useCallback, useRef, useState } from 'react';
import Isvg from 'react-inlinesvg';
import { Link, useLocation } from 'react-router-dom';
import { useToken } from '../hooks/auth/useToken';
import ArrowLeft from '../images/svg/arrow-left2.svg';
import Dashboard from '../images/svg/dashboard.svg';
import Hamburger from '../images/svg/hamburger.svg';
import Listing from '../images/svg/listings.svg';
import Logo from '../images/svg/logo.svg';
import Logout from '../images/svg/logout.svg';
import User from '../images/svg/user.svg';
import AIDesc from '../images/svg/aiDesc.svg';
import { useOnClickOutside } from '../utils/useOnClickOutside';

export const Navbar = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const options = useRef(null);
  const [, setToken] = useToken();

  const handleSidebar = useCallback(() => {
    setOpen(!open)
  }, [open])

  useOnClickOutside(options, () => setOpen(false))

  const handleLogout = useCallback(() => {
    setToken("");
    window.location.href = '/';
  }, [setToken]);

  return (
    <div className="navbar-wrapper">

      <div className="navbar">
        <button className="hamburger" onClick={handleSidebar}><Isvg src={Hamburger} /></button>
        <Isvg src={Logo} />
        {open &&
          <div className="sidebar" ref={options}>
            <div className="arrow-left">
              <button onClick={handleSidebar}> <Isvg src={ArrowLeft} /> </button>
            </div>
            <div className="menu">
              <Link to="/dashboard" className={`item ${location.pathname === '/' && "active"}`}><Isvg src={Dashboard} />Dashboard</Link>
              <Link to="/dashboard-listing" className={`item ${location.pathname === '/dashboard-listing' && "active"}`}> <Isvg src={Listing} /> Listing</Link>
              <Link to="/hello-magic" className={`item ${location.pathname === '/ai-landing' && "active"}`}> <Isvg src={AIDesc} /> Hello:magic AI description</Link>
              
              
              <Link to="/account-settings" className={`item ${location.pathname === '/account-settings' && "active-user"}`}> <Isvg src={User} /> Account</Link>
            </div>

            <button className="item logout" onClick={handleLogout}> <Isvg src={Logout} /> Log out</button>

          </div>
        }
      </div>
    </div>
  )
}