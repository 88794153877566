import React from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import NF from '../images/notfound.png';
import ArrowLeft from '../images/svg/arrow-left copy 2.svg';
import { Layout } from './PageAccount/Layout';

const NotFound = () => {
    return (
        <div className="not-found-page-wrapper">
            <Layout>
                <div className="not-found-page">
                    <div>
                        <div>
                            <h1>Oops! We can’t find
                                that page</h1>
                            <p>This page you were looking for doesn’t exist. Please
                                check your URL for spellings and capitalizations. If you’re
                                having trouble looking for your destination, Try to
                                visit <Link to="/">Mallorcamagic Homepage</Link> </p>
                            <Link to="/" aria-label="go back to home page"><button> <Isvg src={ArrowLeft} /> Go Back</button></Link>
                        </div>
                        <img src={NF} alt="Not Found" width="554" height="374" />
                    </div>
                    <a href="mailto:info@mallorca-magic.com">info@mallorca-magic.com</a>
                </div>
            </Layout>
        </div>
    )
}

export default NotFound;