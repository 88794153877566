import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { HOST_NAME } from '../../config';

export const ListPreview = ({ listing, children }) => {
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimenion]);

  const handleDelete = useCallback(() => {
    axios.delete(`${HOST_NAME}properties/${listing._id}`)
      .then(res => {
        if (res.status === 200) {
          navigate("/dashboard");
        }
      })
      .catch(err => console.log(err));
  }, [listing]);

  return (
    <div className="listing-info-wrapper">
      <div className="listing-info">
        <div className="left">
          <div className="listing-image">
            <img src={listing.src} alt="Listing" />
          </div>
          <div className="info">
            <h4>Listing Title</h4>
            <h4>Agent</h4>
            <h4>Location</h4>
            {windowDimenion.winWidth < 991 &&
              <>
                <h4>Created</h4>
                <h4>Score</h4>
                <h4>Price</h4>
              </>
            }
          </div>
        </div>
        <div className="right">
          <div className="info">
            <h6>{listing.title}</h6>
            {windowDimenion.winWidth > 991 &&
              <div>
                <div className="blue"> <h3>Created</h3> </div>
                <h4>25.02.2022</h4>
              </div>
            }
          </div>
          <div className="info">
            <h6>{listing.agent}</h6>
            {windowDimenion.winWidth > 991 &&
              <div>
                <div className="blue"><h3>Score</h3></div>
                <h4>{listing.score}</h4>
              </div>
            }
          </div>
          <div className="info">
            <h6>{listing.location}</h6>
            {windowDimenion.winWidth > 991 &&
              <div>
                <div className="blue"><h3>Price</h3></div>
                <h4>{listing.price}</h4>
              </div>
            }
          </div>
          {windowDimenion.winWidth < 991 &&
            <>
              <div className="info">
                <h6>25.02.2022</h6>
              </div>
              <div className="info">
                <h6>{listing.score}</h6>
              </div>
              <div className="info">
                <h6>{listing.price}</h6>
              </div>
            </>
          }
        </div>
      </div>
          {children}
    </div>
  )
}