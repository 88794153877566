import React from "react";
import { AddOrCreate } from "../components/add-or-create";
import { DefaultLayout } from "../containers/DefaultLayout";

export const CreateNewListing = () => {
  return (
    <DefaultLayout isPrivate={true}>
      <div className="listing-statistic-wrapper">
        <div className="content">
          <AddOrCreate />
        </div>
      </div>
    </DefaultLayout>
  )
}