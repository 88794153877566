import { useCallback, useEffect, useRef, useState } from 'react';
import Isvg from 'react-inlinesvg';
import ArrowDown from '../../images/svg/arrow-down.svg';
import ArrowUp from '../../images/svg/arrow-up.svg';
import { useOnClickOutside } from '../../utils/useOnClickOutside';

export const Select = ({ data, svg, placeholder, selectValueHandler, name, requiredError, requiredFields, validateValue, pointerArrow }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(placeholder);
  const options = useRef(null);

  useOnClickOutside(options, () => setOpen(false))
  const showOptions = useCallback(() => {
    setOpen(!open)
  }, [open])


  const handleSelect = useCallback((selected) => {
    selectValueHandler({ [name]: selected, name: name });
    setValue(selected)
    setOpen(false);
  }, [name, selectValueHandler])

  useEffect(() => {
    setValue(placeholder || data[0].value);
  }, [data, placeholder]);


  return (
    <>
      <div className="select-input-wrapper" onClick={showOptions} ref={options}>
        {svg && <Isvg src={svg} />} <h6> {value}</h6> {open ? <Isvg src={pointerArrow || ArrowUp} className="up" /> : <Isvg src={pointerArrow || ArrowDown} className="down"/>}
        {open &&
          <div className="result">
            {data.map((item, _idx) => {
              return (
                <h6 key={_idx} onClick={() => handleSelect(item.value)}>{item.text}</h6>
              )
            })}

          </div>
        }

      </div>
      {requiredError && requiredFields && !validateValue &&
        <div className="error">
          this field is required!
        </div>
      }
    </>
  )
}