import React from 'react';
import Carousel from 'nuka-carousel';


export const CarouselSlider = ({ slider }) => {
  return (
    <div className="carousel-wrapper">
      <Carousel>
        {slider.map((item, _idx) => {
          return (
            <img key={_idx} src={item.src} alt="login" />
          )
        })}
      </Carousel>
    </div>
  )
}