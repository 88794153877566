import { useCallback, useEffect, useState } from "react";
import { RadioButton } from "../RadioButton";
import { useBasicInfoFormConfig } from "./useFormConfig";

export const BasicInfo = ({ onChange, showErrors }) => {
    const [basicInfo, setBasicInfo] = useState({});

    const handleType = useCallback((event) => {
        setBasicInfo((prevState) => ({
            ...prevState, transactionType
                : event.target.name
        }))
    }, [])

    const handleLanguage = useCallback((event) => {
        setBasicInfo((prevState) => ({ ...prevState, language: event.target.name }))
    }, [])

    const handleLocation = useCallback((event) => setBasicInfo((prevState) => ({ ...prevState, [event.target.name]: event.target.value })), [])

    useEffect(() => {
        onChange(basicInfo)
    }, [basicInfo, onChange])


    const basicInfoFormConfig = useBasicInfoFormConfig(handleType, handleLanguage, basicInfo)

    return (
        <div className="basic-info-wrapper">
            <div className="basic-info">
                <div className='title'>Property Location</div>
                <div className="input">
                    <input type="text" name="location" placeholder='Enter accurate property location' onChange={handleLocation} />
                </div>
                {showErrors && !basicInfo.location && <div className='error'>Property type is Required</div>}
            </div>
            {basicInfoFormConfig.map((item, _idx) => {
                return (
                    <div className="basic-info" key={_idx}>
                        <div className='title'>{item.title}</div>
                        <div className="input">
                            {item.inputs.map((input, _idx) => {
                                return (
                                    <RadioButton
                                        key={_idx}
                                        name={input.name}
                                        onChange={input.onChange}
                                        label={input.label}
                                        value={input.value}
                                    />
                                )
                            })}
                        </div>
                        {item.title === 'Transaction type' && showErrors ? !basicInfo.transactionType && <div className='error'>{item.title} is Required</div> : null}
                        {item.title === 'Description language' && showErrors ? !basicInfo.language && <div className='error'>{item.title} is Required</div> : null}
                    </div>
                )
            })}
        </div>
    )
}