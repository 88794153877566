import React from 'react'
import HHLogo from '../../../images/svg/HHLogo.svg'
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

export const Navbar = () => {
    return (
        <div className="account-page-navbar-wrapper">
            <Isvg src={HHLogo} />
            <Link to="/login"><button>Login</button></Link>
        </div>
    )
}