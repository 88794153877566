import React, { useCallback } from 'react'
import Isvg from 'react-inlinesvg';
import Email from '../../../images/svg/email.svg';


export const EmailInput = ({ setEmail, email }) => {

  const handleEmail = useCallback((e) => {
    setEmail(e.target.value)
  }, [setEmail])

  return (
    <div className="input-email-wrapper">
      <label>Email Address</label>
      <div className="email-wrapper">
        <Isvg src={Email} />
        <input type="text" name="email" value={email} onChange={handleEmail} />
      </div>
    </div>

  )
}