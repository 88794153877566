import React, { useCallback, useRef, useState } from 'react';
import Isvg from 'react-inlinesvg';
import AddNew from '../../images/svg/add-new.svg';
import CloudPlus from '../../images/svg/cloud-plus.svg';
import Close from '../../images/svg/close.svg';
import DocumentUpload from '../../images/svg/document-upload.svg'
import { Link } from 'react-router-dom';
import Back from '../../images/svg/back-square.svg'
import { Select } from '../select';
import CheckMark from '../../images/svg/check-mark.svg';
import axios from 'axios';
import { HOST_NAME } from '../../config';

const statistic = [
  {
    _id: 1,
    text: "Select listing category"
  },
  {
    _id: 2,
    text: "Select listing category"
  },
  {
    _id: 3,
    text: "Select listing category"
  },
  {
    _id: 4,
    text: "Select listing category"
  },
]

export const Modal = ({ handleOpenModal, openModal }) => {
  const [step, setStep] = useState(1);
  const [selectCategory, setSelectCategory] = useState();
  const [check, setCheck] = useState(false);

  const handleOpen = useCallback(() => {
    handleOpenModal(false)
  }, [handleOpenModal])

  const handleSelect = useCallback((value) => {
    setSelectCategory(value[value.name]);
  }, [])

  const handleCheckBox = useCallback(() => {
    setCheck(!check);
  }, [check]);

  // Url import
  const urlInput = useRef();
  const [loadingImportUrl, setLoadingImportUrl] = useState(false);
  const handleImportUrl = useCallback(() => {
    if (!urlInput.current.value) return;
    if (!check) {
      alert("Please accept the terms and conditions");
      return;
    }
    setLoadingImportUrl(true);
    axios.post(`${HOST_NAME}import`, { url: urlInput.current.value })
      .then(res => {
        if (res.status === 200) {
          alert("Request saved successfully");
        }
      })
      .catch(err => alert("Request failed with status: " + err.status))
      .finally(() => setLoadingImportUrl(false));
  }, [urlInput, check]);

  return openModal && (
    <div className="modal-background">
      {step === 1 &&
        <div className="modal-create-new-listing-wrapper">
          <div className="header"> <button onClick={handleOpen}> <Isvg src={Close} /> </button> </div>
          <div className="heading-import">
            <h1>The easy way to add your properties</h1>
            <p>It has never been so easy to link your data, we only need your website URL. We'll take care of the rest. As a rule, your objects are live with us within 48 hours</p>
            <input type="text" placeholder="http://www.realstatemallorca.com" ref={urlInput} />
            <div className="terms">
              <div className="checkbox-wrapper" >
                <div className="checkbox" onClick={handleCheckBox}>
                  {check && <Isvg src={CheckMark} />}
                </div>
              </div>
              <h5> I have read and agree to the <Link to="#">terms and conditions</Link> </h5>
            </div>
            <button onClick={handleImportUrl} disabled={loadingImportUrl}>{loadingImportUrl ? "SAVING IMPORT REQUEST..." : "START IMPORT"}</button>
          </div>
          <div className="row">
            <div className="left">
              <Isvg src={AddNew} />
              <h6>Add Listing Manually</h6>
              <p>Create listing one by one. You can
                save draft at any time.</p>
                <Link to="/create-listing">
                  <button>CREATE NEW LISTING</button>
                </Link>
            </div>
            <div className="right">
              <Isvg src={CloudPlus} />
              <h6>Import listings autometically</h6>
              <p>Use XML feed to upload listings in
                bulk. Get the feed from your MLS, CRM
                or a web developer</p>
              <button onClick={() => setStep(2)}>IMPORT LISTINGS</button>
            </div>
          </div>
        </div>
      }
      {step === 2 &&
        <div className="modal-create-new-listing-wrapper">
          <div className="content">
            <div className="header"> <button onClick={() => setStep(1)}> <Isvg src={Back} /> </button> </div>
            <Isvg src={DocumentUpload} />
            <h6>XML Import Listing</h6>
            <p>We have our own feed format. you can
              convert your feed to the Hellohere format
              by using <Link to="/">feed documentation</Link> </p>
            <h3>Paste Hellohere feed URL</h3>
            <input type="text" placeholder="ftp://helloherelist:utueoggehehffp.list.com/helloherelist.xml" />
            <button>START IMPORT</button>
          </div>
        </div>
      }
      {step === 3 &&
        <div className="modal-create-new-listing-wrapper">
          <div className="content">
            <div className="header"> <button onClick={() => setStep(1)}> <Isvg src={Back} /> </button> </div>
            <div className="last-modal">
              <h6>Create new listing</h6>
              <p>Please provide information about the listing in the following form.
                You can save it as a draft and came back to it later</p>
              <Select data={statistic} placeholder="Select listing category" name="listingCategory" selectValueHandler={handleSelect} />
              <div className="button-wrapper">
                <Link to="/create-listing"><button>ADD NEW LISTING</button></Link>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}