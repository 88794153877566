import { useMemo } from "react"

export const useContryCodeList = () => {
    return useMemo(() =>
    (
        [
            {
                text: "+34",
                value: "+34",
                name: "Spain"
            },
            {
                text: "+7840",
                value: "+7840",
                name: "Abkhazia"
            },
            {
                text: "+93",
                value: "+93",
                name: "Afghanistan"
            },
            {
                text: "+355",
                value: "+355",
                name: "Albania"
            },
            {
                text: "+213",
                value: "+213",
                name: "Algeria"
            },
            {
                text: "+1684",
                value: "+1684",
                name: "AmericanSamoa"
            },
            {
                text: "+376",
                value: "+376",
                name: "Andorra"
            },
            {
                text: "+244",
                value: "+244",
                name: "Angola"
            },
            {
                text: "+1264",
                value: "+1264",
                name: "Anguilla"
            },
            {
                text: "+1268",
                value: "+1268",
                name: "AntiguaandBarbuda"
            },
            {
                text: "+54",
                value: "+54",
                name: "Argentina"
            },
            {
                text: "+374",
                value: "+374",
                name: "Armenia"
            },
            {
                text: "+297",
                value: "+297",
                name: "Aruba"
            },
            {
                text: "+247",
                value: "+247",
                name: "Ascension"
            },
            {
                text: "+61",
                value: "+61",
                name: "Australia"
            },
            {
                text: "+672",
                value: "+672",
                name: "AustralianExternalTerritories"
            },
            {
                text: "+43",
                value: "+43",
                name: "Austria"
            },
            {
                text: "+994",
                value: "+994",
                name: "Azerbaijan"
            },
            {
                text: "+1242",
                value: "+1242",
                name: "Bahamas"
            },
            {
                text: "+973",
                value: "+973",
                name: "Bahrain"
            },
            {
                text: "+880",
                value: "+880",
                name: "Bangladesh"
            },
            {
                text: "+1246",
                value: "+1246",
                name: "Barbados"
            },
            {
                text: "+1268",
                value: "+1268",
                name: "Barbuda"
            },
            {
                text: "+375",
                value: "+375",
                name: "Belarus"
            },
            {
                text: "+32",
                value: "+32",
                name: "Belgium"
            },
            {
                text: "+501",
                value: "+501",
                name: "Belize"
            },
            {
                text: "+229",
                value: "+229",
                name: "Benin"
            },
            {
                text: "+1441",
                value: "+1441",
                name: "Bermuda"
            },
            {
                text: "+975",
                value: "+975",
                name: "Bhutan"
            },
            {
                text: "+591",
                value: "+591",
                name: "Bolivia"
            },
            {
                text: "+387",
                value: "+387",
                name: "BosniaandHerzegovina"
            },
            {
                text: "+267",
                value: "+267",
                name: "Botswana"
            },
            {
                text: "+55",
                value: "+55",
                name: "Brazil"
            },
            {
                text: "+246",
                value: "+246",
                name: "BritishIndianOceanTerritory"
            },
            {
                text: "+1284",
                value: "+1284",
                name: "BritishVirginIslands"
            },
            {
                text: "+673",
                value: "+673",
                name: "Brunei"
            },
            {
                text: "+359",
                value: "+359",
                name: "Bulgaria"
            },
            {
                text: "+226",
                value: "+226",
                name: "BurkinaFaso"
            },
            {
                text: "+257",
                value: "+257",
                name: "Burundi"
            },
            {
                text: "+855",
                value: "+855",
                name: "Cambodia"
            },
            {
                text: "+237",
                value: "+237",
                name: "Cameroon"
            },
            {
                text: "+1",
                value: "+1",
                name: "Canada"
            },
            {
                text: "+238",
                value: "+238",
                name: "CapeVerde"
            },
            {
                text: "+345",
                value: "+345",
                name: "CaymanIslands"
            },
            {
                text: "+236",
                value: "+236",
                name: "CentralAfricanRepublic"
            },
            {
                text: "+235",
                value: "+235",
                name: "Chad"
            },
            {
                text: "+56",
                value: "+56",
                name: "Chile"
            },
            {
                text: "+86",
                value: "+86",
                name: "China"
            },
            {
                text: "+61",
                value: "+61",
                name: "ChristmasIsland"
            },
            {
                text: "+61",
                value: "+61",
                name: "Cocos-KeelingIslands"
            },
            {
                text: "+57",
                value: "+57",
                name: "Colombia"
            },
            {
                text: "+269",
                value: "+269",
                name: "Comoros"
            },
            {
                text: "+242",
                value: "+242",
                name: "Congo"
            },
            {
                text: "+243",
                value: "+243",
                name: "Congo,Dem.Rep.of(Zaire)"
            },
            {
                text: "+682",
                value: "+682",
                name: "CookIslands"
            },
            {
                text: "+506",
                value: "+506",
                name: "CostaRica"
            },
            {
                text: "+385",
                value: "+385",
                name: "Croatia"
            },
            {
                text: "+53",
                value: "+53",
                name: "Cuba"
            },
            {
                text: "+599",
                value: "+599",
                name: "Curacao"
            },
            {
                text: "+537",
                value: "+537",
                name: "Cyprus"
            },
            {
                text: "+420",
                value: "+420",
                name: "CzechRepublic"
            },
            {
                text: "+45",
                value: "+45",
                name: "Denmark"
            },
            {
                text: "+246",
                value: "+246",
                name: "DiegoGarcia"
            },
            {
                text: "+253",
                value: "+253",
                name: "Djibouti"
            },
            {
                text: "+1767",
                value: "+1767",
                name: "Dominica"
            },
            {
                text: "+1809",
                value: "+1809",
                name: "DominicanRepublic"
            },
            {
                text: "+670",
                value: "+670",
                name: "EastTimor"
            },
            {
                text: "+56",
                value: "+56",
                name: "EasterIsland"
            },
            {
                text: "+593",
                value: "+593",
                name: "Ecuador"
            },
            {
                text: "+20",
                value: "+20",
                name: "Egypt"
            },
            {
                text: "+503",
                value: "+503",
                name: "ElSalvador"
            },
            {
                text: "+240",
                value: "+240",
                name: "EquatorialGuinea"
            },
            {
                text: "+291",
                value: "+291",
                name: "Eritrea"
            },
            {
                text: "+372",
                value: "+372",
                name: "Estonia"
            },
            {
                text: "+251",
                value: "+251",
                name: "Ethiopia"
            },
            {
                text: "+500",
                value: "+500",
                name: "FalklandIslands"
            },
            {
                text: "+298",
                value: "+298",
                name: "FaroeIslands"
            },
            {
                text: "+679",
                value: "+679",
                name: "Fiji"
            },
            {
                text: "+358",
                value: "+358",
                name: "Finland"
            },
            {
                text: "+33",
                value: "+33",
                name: "France"
            },
            {
                text: "+596",
                value: "+596",
                name: "FrenchAntilles"
            },
            {
                text: "+594",
                value: "+594",
                name: "FrenchGuiana"
            },
            {
                text: "+689",
                value: "+689",
                name: "FrenchPolynesia"
            },
            {
                text: "+241",
                value: "+241",
                name: "Gabon"
            },
            {
                text: "+220",
                value: "+220",
                name: "Gambia"
            },
            {
                text: "+995",
                value: "+995",
                name: "Georgia"
            },
            {
                text: "+49",
                value: "+49",
                name: "Germany"
            },
            {
                text: "+233",
                value: "+233",
                name: "Ghana"
            },
            {
                text: "+350",
                value: "+350",
                name: "Gibraltar"
            },
            {
                text: "+30",
                value: "+30",
                name: "Greece"
            },
            {
                text: "+299",
                value: "+299",
                name: "Greenland"
            },
            {
                text: "+1473",
                value: "+1473",
                name: "Grenada"
            },
            {
                text: "+590",
                value: "+590",
                name: "Guadeloupe"
            },
            {
                text: "+1671",
                value: "+1671",
                name: "Guam"
            },
            {
                text: "+502",
                value: "+502",
                name: "Guatemala"
            },
            {
                text: "+224",
                value: "+224",
                name: "Guinea"
            },
            {
                text: "+245",
                value: "+245",
                name: "Guinea-Bissau"
            },
            {
                text: "+595",
                value: "+595",
                name: "Guyana"
            },
            {
                text: "+509",
                value: "+509",
                name: "Haiti"
            },
            {
                text: "+504",
                value: "+504",
                name: "Honduras"
            },
            {
                text: "+852",
                value: "+852",
                name: "HongKongSARChina"
            },
            {
                text: "+36",
                value: "+36",
                name: "Hungary"
            },
            {
                text: "+354",
                value: "+354",
                name: "Iceland"
            },
            {
                text: "+91",
                value: "+91",
                name: "India"
            },
            {
                text: "+62",
                value: "+62",
                name: "Indonesia"
            },
            {
                text: "+98",
                value: "+98",
                name: "Iran"
            },
            {
                text: "+964",
                value: "+964",
                name: "Iraq"
            },
            {
                text: "+353",
                value: "+353",
                name: "Ireland"
            },
            {
                text: "+972",
                value: "+972",
                name: "Israel"
            },
            {
                text: "+39",
                value: "+39",
                name: "Italy"
            },
            {
                text: "+225",
                value: "+225",
                name: "IvoryCoast"
            },
            {
                text: "+1876",
                value: "+1876",
                name: "Jamaica"
            },
            {
                text: "+81",
                value: "+81",
                name: "Japan"
            },
            {
                text: "+962",
                value: "+962",
                name: "Jordan"
            },
            {
                text: "+77",
                value: "+77",
                name: "Kazakhstan"
            },
            {
                text: "+254",
                value: "+254",
                name: "Kenya"
            },
            {
                text: "+686",
                value: "+686",
                name: "Kiribati"
            },
            {
                text: "+965",
                value: "+965",
                name: "Kuwait"
            },
            {
                text: "+996",
                value: "+996",
                name: "Kyrgyzstan"
            },
            {
                text: "+856",
                value: "+856",
                name: "Laos"
            },
            {
                text: "+371",
                value: "+371",
                name: "Latvia"
            },
            {
                text: "+961",
                value: "+961",
                name: "Lebanon"
            },
            {
                text: "+266",
                value: "+266",
                name: "Lesotho"
            },
            {
                text: "+231",
                value: "+231",
                name: "Liberia"
            },
            {
                text: "+218",
                value: "+218",
                name: "Libya"
            },
            {
                text: "+423",
                value: "+423",
                name: "Liechtenstein"
            },
            {
                text: "+370",
                value: "+370",
                name: "Lithuania"
            },
            {
                text: "+352",
                value: "+352",
                name: "Luxembourg"
            },
            {
                text: "+853",
                value: "+853",
                name: "MacauSARChina"
            },
            {
                text: "+389",
                value: "+389",
                name: "Macedonia"
            },
            {
                text: "+261",
                value: "+261",
                name: "Madagascar"
            },
            {
                text: "+265",
                value: "+265",
                name: "Malawi"
            },
            {
                text: "+60",
                value: "+60",
                name: "Malaysia"
            },
            {
                text: "+960",
                value: "+960",
                name: "Maldives"
            },
            {
                text: "+223",
                value: "+223",
                name: "Mali"
            },
            {
                text: "+356",
                value: "+356",
                name: "Malta"
            },
            {
                text: "+692",
                value: "+692",
                name: "MarshallIslands"
            },
            {
                text: "+596",
                value: "+596",
                name: "Martinique"
            },
            {
                text: "+222",
                value: "+222",
                name: "Mauritania"
            },
            {
                text: "+230",
                value: "+230",
                name: "Mauritius"
            },
            {
                text: "+262",
                value: "+262",
                name: "Mayotte"
            },
            {
                text: "+52",
                value: "+52",
                name: "Mexico"
            },
            {
                text: "+691",
                value: "+691",
                name: "Micronesia"
            },
            {
                text: "+1808",
                value: "+1808",
                name: "MidwayIsland"
            },
            {
                text: "+373",
                value: "+373",
                name: "Moldova"
            },
            {
                text: "+377",
                value: "+377",
                name: "Monaco"
            },
            {
                text: "+976",
                value: "+976",
                name: "Mongolia"
            },
            {
                text: "+382",
                value: "+382",
                name: "Montenegro"
            },
            {
                text: "+1664",
                value: "+1664",
                name: "Montserrat"
            },
            {
                text: "+212",
                value: "+212",
                name: "Morocco"
            },
            {
                text: "+95",
                value: "+95",
                name: "Myanmar"
            },
            {
                text: "+264",
                value: "+264",
                name: "Namibia"
            },
            {
                text: "+674",
                value: "+674",
                name: "Nauru"
            },
            {
                text: "+977",
                value: "+977",
                name: "Nepal"
            },
            {
                text: "+31",
                value: "+31",
                name: "Netherlands"
            },
            {
                text: "+599",
                value: "+599",
                name: "NetherlandsAntilles"
            },
            {
                text: "+1869",
                value: "+1869",
                name: "Nevis"
            },
            {
                text: "+687",
                value: "+687",
                name: "NewCaledonia"
            },
            {
                text: "+64",
                value: "+64",
                name: "NewZealand"
            },
            {
                text: "+505",
                value: "+505",
                name: "Nicaragua"
            },
            {
                text: "+227",
                value: "+227",
                name: "Niger"
            },
            {
                text: "+234",
                value: "+234",
                name: "Nigeria"
            },
            {
                text: "+683",
                value: "+683",
                name: "Niue"
            },
            {
                text: "+672",
                value: "+672",
                name: "NorfolkIsland"
            },
            {
                text: "+850",
                value: "+850",
                name: "NorthKorea"
            },
            {
                text: "+1670",
                value: "+1670",
                name: "NorthernMarianaIslands"
            },
            {
                text: "+47",
                value: "+47",
                name: "Norway"
            },
            {
                text: "+968",
                value: "+968",
                name: "Oman"
            },
            {
                text: "+92",
                value: "+92",
                name: "Pakistan"
            },
            {
                text: "+680",
                value: "+680",
                name: "Palau"
            },
            {
                text: "+970",
                value: "+970",
                name: "PalestinianTerritory"
            },
            {
                text: "+507",
                value: "+507",
                name: "Panama"
            },
            {
                text: "+675",
                value: "+675",
                name: "PapuaNewGuinea"
            },
            {
                text: "+595",
                value: "+595",
                name: "Paraguay"
            },
            {
                text: "+51",
                value: "+51",
                name: "Peru"
            },
            {
                text: "+63",
                value: "+63",
                name: "Philippines"
            },
            {
                text: "+48",
                value: "+48",
                name: "Poland"
            },
            {
                text: "+351",
                value: "+351",
                name: "Portugal"
            },
            {
                text: "+1787",
                value: "+1787",
                name: "PuertoRico"
            },
            {
                text: "+974",
                value: "+974",
                name: "Qatar"
            },
            {
                text: "+262",
                value: "+262",
                name: "Reunion"
            },
            {
                text: "+40",
                value: "+40",
                name: "Romania"
            },
            {
                text: "+7",
                value: "+7",
                name: "Russia"
            },
            {
                text: "+250",
                value: "+250",
                name: "Rwanda"
            },
            {
                text: "+685",
                value: "+685",
                name: "Samoa"
            },
            {
                text: "+378",
                value: "+378",
                name: "SanMarino"
            },
            {
                text: "+966",
                value: "+966",
                name: "SaudiArabia"
            },
            {
                text: "+221",
                value: "+221",
                name: "Senegal"
            },
            {
                text: "+381",
                value: "+381",
                name: "Serbia"
            },
            {
                text: "+248",
                value: "+248",
                name: "Seychelles"
            },
            {
                text: "+232",
                value: "+232",
                name: "SierraLeone"
            },
            {
                text: "+65",
                value: "+65",
                name: "Singapore"
            },
            {
                text: "+421",
                value: "+421",
                name: "Slovakia"
            },
            {
                text: "+386",
                value: "+386",
                name: "Slovenia"
            },
            {
                text: "+677",
                value: "+677",
                name: "SolomonIslands"
            },
            {
                text: "+27",
                value: "+27",
                name: "SouthAfrica"
            },
            {
                text: "+500",
                value: "+500",
                name: "SouthGeorgiaandtheSouthSandwichIslands"
            },
            {
                text: "+82",
                value: "+82",
                name: "SouthKorea"
            },
            {
                text: "+94",
                value: "+94",
                name: "SriLanka"
            },
            {
                text: "+249",
                value: "+249",
                name: "Sudan"
            },
            {
                text: "+597",
                value: "+597",
                name: "Suriname"
            },
            {
                text: "+268",
                value: "+268",
                name: "Swaziland"
            },
            {
                text: "+46",
                value: "+46",
                name: "Sweden"
            },
            {
                text: "+41",
                value: "+41",
                name: "Switzerland"
            },
            {
                text: "+963",
                value: "+963",
                name: "Syria"
            },
            {
                text: "+886",
                value: "+886",
                name: "Taiwan"
            },
            {
                text: "+992",
                value: "+992",
                name: "Tajikistan"
            },
            {
                text: "+255",
                value: "+255",
                name: "Tanzania"
            },
            {
                text: "+66",
                value: "+66",
                name: "Thailand"
            },
            {
                text: "+670",
                value: "+670",
                name: "TimorLeste"
            },
            {
                text: "+228",
                value: "+228",
                name: "Togo"
            },
            {
                text: "+690",
                value: "+690",
                name: "Tokelau"
            },
            {
                text: "+676",
                value: "+676",
                name: "Tonga"
            },
            {
                text: "+1868",
                value: "+1868",
                name: "TrinidadandTobago"
            },
            {
                text: "+216",
                value: "+216",
                name: "Tunisia"
            },
            {
                text: "+90",
                value: "+90",
                name: "Turkey"
            },
            {
                text: "+993",
                value: "+993",
                name: "Turkmenistan"
            },
            {
                text: "+1649",
                value: "+1649",
                name: "TurksandCaicosIslands"
            },
            {
                text: "+688",
                value: "+688",
                name: "Tuvalu"
            },
            {
                text: "+1340",
                value: "+1340",
                name: "U.S.VirginIslands"
            },
            {
                text: "+256",
                value: "+256",
                name: "Uganda"
            },
            {
                text: "+380",
                value: "+380",
                name: "Ukraine"
            },
            {
                text: "+971",
                value: "+971",
                name: "UnitedArabEmirates"
            },
            {
                text: "+44",
                value: "+44",
                name: "UnitedKingdom"
            },
            {
                text: "+1",
                value: "+1",
                name: "UnitedStates"
            },
            {
                text: "+598",
                value: "+598",
                name: "Uruguay"
            },
            {
                text: "+998",
                value: "+998",
                name: "Uzbekistan"
            },
            {
                text: "+678",
                value: "+678",
                name: "Vanuatu"
            },
            {
                text: "+58",
                value: "+58",
                name: "Venezuela"
            },
            {
                text: "+84",
                value: "+84",
                name: "Vietnam"
            },
            {
                text: "+1808",
                value: "+1808",
                name: "WakeIsland"
            },
            {
                text: "+681",
                value: "+681",
                name: "WallisandFutuna"
            },
            {
                text: "+967",
                value: "+967",
                name: "Yemen"
            },
            {
                text: "+260",
                value: "+260",
                name: "Zambia"
            },
            {
                text: "+255",
                value: "+255",
                name: "Zanzibar"
            },
            {
                text: "+263",
                value: "+263",
                name: "Zimbabwe"
            }

        ]
    ), [])
}
