import { Navigate } from 'react-router-dom';
import { useUser } from './useUser';

const PrivateRoute = (props) => {
    const user = useUser();

    if (!user) return <Navigate to="/login" />;
    return <>{props.children}</>;
}

export default PrivateRoute;