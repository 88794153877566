import { useCallback } from 'react';
import { SearchPhoneSelect } from '../inputs/searchPhoneSelect';


export const PhoneSelect = ({ phoneHandler, name, textName, textValue, handleError }) => {
  const handleAreaCode = useCallback((value) => {
    phoneHandler({ ...value, [value.name]: value[value.name] })
  }, [phoneHandler])

  const handlePhoneNumber = useCallback((e) => {
    phoneHandler(({ ...textValue, [e.target.name]: e.target.value }))
  }, [phoneHandler, textValue])

  const errorHandler = useCallback((value) => {
    if (value) {
      handleError(value);
      return;
    }
    handleError();
  }, [handleError])

  return (
    <div className="phone-select-wrapper">
      <SearchPhoneSelect selectValueHandler={handleAreaCode} name={name} value={textValue.areaCode ?? ""} phoneNumber={textValue[textName] ?? ""} errorHandler={errorHandler} />
      <input type="text" placeholder="634 30 37 79" name={textName} value={textValue[textName] ?? ""} onChange={handlePhoneNumber} />
    </div>
  )
}