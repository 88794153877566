import React, { useCallback, useState } from "react";
import { useDropzone } from 'react-dropzone';
import Isvg from 'react-inlinesvg';
import DeleteIcon from '../../images/svg/Delete button.svg';


export const DaDropZone = ({ photos: myFiles, setPhotos }) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSetPhotos = useCallback((values) => {
    setPhotos(values)
  }, [setPhotos])

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setError(false);
    setErrorMessage('');

    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "too-many-files") {
          setErrorMessage(`Error: ${err.message}`);
          setError(true);
        }

        if (err.code === "file-invalid-type") {
          setErrorMessage(`Error: ${err.message}`);
          setError(true);
        }
      })
    })
    handleSetPhotos([...acceptedFiles]);
  }, [handleSetPhotos])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxFiles: 10,
    multiple: true,
  })

  const removeFile = useCallback((i) => {
    var newFiles = [...myFiles];
    const index = myFiles.indexOf(i);

    if (index !== -1) {
      newFiles.splice(index, 1);
      handleSetPhotos([...newFiles]);
    }
  }, [myFiles, handleSetPhotos])

  return (
    <section className="dropzone-wrapper">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {!myFiles.length &&
          <>
            <button>UPLOAD PHOTOS</button>
            <p>or drag them here</p>
          </>
        }
        {error ? errorMessage : ""}
      </div>
      {myFiles.length &&
        <div className="preview">
          {myFiles.map((file, _idx) => (
            <div key={`${file.path}-${_idx}`}>
              <button className="remove" onClick={() => removeFile(file)}><Isvg src={DeleteIcon} /></button>
              <img src={URL.createObjectURL(file)} alt="Preview" />
            </div>
          ))}
        </div>}
    </section>
  );
}
