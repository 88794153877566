import {
  CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
  Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Isvg from 'react-inlinesvg';
import Information from '../../images/svg/information.svg';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  tooltips: { enabled: false },
  hover: { mode: null },
  maintainAspectRatio: false,
  plugins: {

    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0
    }
  },
  scales: {
    x: {
      display: false,
      grid: {
        display: false
      }
    },
    y: {
      display: false,
      grid: {
        display: false
      }
    }
  }
};

export const LineChart = ({ data }) => {
  return (
    <div className="line-chart-wrapper">
      <div className="header">
        <div>
          <h6>{data.title}</h6>
          <h5>{data.value}</h5>
        </div>
        <Isvg src={Information} />
      </div>
      <div className="line-chart">
        <Line options={options} data={data} />
      </div>
    </div>
  );
}
