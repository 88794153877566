import React from "react";

export const Spinner = () => {
  return (
    <div className="spinner-wrapper">
      <div className="example">
        <span className="grow-pulse spinner" />
      </div>
    </div>
  )
}