import { faker } from '@faker-js/faker';
import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { HOST_NAME } from '../../config';
import { DefaultLayout } from "../../containers/DefaultLayout";
import ListingPreview from '../../images/listing-preview.png';
import { EditListing } from './EditListing';
import { Promote } from './Promote';
import { Statistic } from './Statistic';
import { useToken } from '../../hooks/auth/useToken';


const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const listing = {
  _id: 1,
  title: "Modern Villa in the beverly",
  location: "Mallorca",
  agent: "John Doe",
  pageView: 100,
  leads: 40,
  score: 40,
  price: "2,655,000",
  src: ListingPreview
}

export const data = [
  {
    labels: labels,
    datasets: [
      {
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: '#3176BC',
        backgroundColor: '#3176BC',
      }
    ],
    title: "PAGEVIEWS",
    value: 120
  },
  {
    labels: labels,
    datasets: [
      {
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: '#3176BC',
        backgroundColor: '#3176BC',
      }
    ],
    title: "LISTING SAVES",
    value: 17
  },
  {
    labels: labels,
    datasets: [
      {
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: '#3176BC',
        backgroundColor: '#3176BC',
      }
    ],
    title: "BUSINESS PAGE CLICKS",
    value: 120
  },
  {
    labels: labels,
    datasets: [
      {
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: '#3176BC',
        backgroundColor: '#3176BC',
      }
    ],
    title: "LEADS",
    value: 2
  },
  {
    labels: labels,
    datasets: [
      {
        data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: '#3176BC',
        backgroundColor: '#3176BC',
      }
    ],
    title: "TIME ON LISTING PAGE, MINUTES",
    value: "00:23"
  }
]

export const ListStatistic = () => {
  const { type, id } = useParams();
  const [editableData, setEditableData] = useState(null);
  const [previewData, setPreviewData] = useState(listing);
  const [token,] = useToken();

  useEffect(() => {
    if (id) {
      axios.get(`${HOST_NAME}properties/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(res => {
          // For listing preview
          const preview = {
            _id: res.data.slug,
            title: res.data.details.title,
            location: res.data.details.loc_city,
            agent: res.data.details.partner,
            pageView: 100,
            leads: 40,
            score: 40,
            price: `${res.data.details.price_unit} ${res.data.details.price_amount}`,
            src: res.data.thumbnail[0]
          };
          setPreviewData(preview);

          // for edit data
          const editData = {
            __id: res.data.id,
            category: res.data.details.category,
            listingPreview: res.data.thumbnail[0],
            listingPreviewFile: null,
            office: res.data.details.partner,
            propertyType: res.data.details.type,
            price: res.data.details.price_amount,
            priceCurrency: res.data.details.price_unit,
            showPrice: res.data.details.price_display,
            title: res.data.details.title,
            description: res.data.details.description,

            address: res.data.details.loc_street,
            zipCode: res.data.details.loc_zip,
            city: res.data.details.loc_city,
            licenseNumber: res.data.details.license_number,

            preOwned: res.data.details.owned,
            builtYear: res.data.details.built_at,
            bedrooms: res.data.details.bedrooms,
            bathrooms: res.data.details.bathrooms,
            floors: res.data.details.floors,
            livingArea: res.data.details.size_construction,
            landArea: res.data.details.size_plot,
            livingAreaM2: res.data.details.size_living_unit,
            landAreaM2: res.data.details.size_living_unit,

            view: "",
            outdoor: "",
            indoor: "",
            landfeatures: "",

            longtitude: res.data.details.loc_lng,
            latitude: res.data.details.loc_lat,

            youtube: res.data.details.video,
            virtualTour: res.data.details.tour,
            selectedView: res.data.details.exterior_views.split(","),
            photos: []
          };
          setEditableData(editData);
        })
        .catch(err => console.log(err))
        .finally(() => { });
    }
  }, [id, token])

  return (
    <DefaultLayout isPrivate={true}>
      <div className="listing-statistic-wrapper">
        <div className="content">
          {type === 'statistic' && <Statistic data={data} previewData={previewData} id={id} type={type} />}

          {type === 'edit' && <EditListing previewData={previewData} editableData={editableData} id={id} type={type} />}

          {type === 'promote' && <Promote previewData={previewData} id={id} type={type} />}
        </div>
      </div>
    </DefaultLayout >
  )
}