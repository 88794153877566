import Isvg from 'react-inlinesvg';
import { Title } from '../../components/Title';
import SecondAccountBGC from '../../images/account-bgc-2.png';
import AccountsBGC from '../../images/backg-accounts-page.png';
import MockupCustomer from '../../images/mockup-customer.png';
import Cancel from '../../images/svg/cancel.svg';
import LifeOfSpain from '../../images/svg/Design ohne Titel 1.png';
import HHLogo from '../../images/svg/HHLogo.svg';
import Lead from '../../images/svg/lead.svg';
import MMLogo from '../../images/svg/MM Full Logo.svg';
import Mockup from '../../images/svg/social/mockup.png';
import Unlimited from '../../images/svg/unlimited.svg';
import { Folowers } from './Folowers';
import { Layout } from './Layout';
import { OneClick } from './OneClick';
import { useAccountSettings } from './useAccountConfig';

export const PageAccounts = () => {
  const {folowersHH, folowersMM, folowersLS } = useAccountSettings()

    return (
        <div className="page-account-wrapper">
            <Layout>
                <OneClick
                    banner
                    background={AccountsBGC}
                    title="Take your real estate business in a new height"
                    subtitle="<span>JOIN US</span> and promote yourself to the world."
                />
                <Title text="We are the first real estate marketplace on social media" />

                <div className="gradient-wrapper">
                    <h1>HELLO PROFESSIONALS!</h1>
                    <p>Publish your properties with one click on the first social media marketplace for real estate</p>
                </div>

                <div className="folowers-wrapper">
                    <h1>Take advantage of our exceptional reach with our brands:</h1>
                    <div className="content">
                        <div>
                            <div className="logo">
                                <Isvg src={MMLogo} />
                            </div>
                            {folowersMM.map((item, _id) => {
                                return (
                                    <Folowers key={_id} svg={item.svg} numbers={item.numbers} text={item.text} border={item.border} color={item.color} />
                                )
                            })}

                        </div>
                        <div>
                            <div className="logo">
                                <img src={LifeOfSpain} alt="Life of spain"/>
                            </div>

                            {folowersLS.map((item, _id) => {
                                return (
                                    <Folowers key={_id} svg={item.svg} numbers={item.numbers} text={item.text} border={item.border} color={item.color} />
                                )
                            })}

                        </div>
                        <div>
                            <div className="logo">
                                <Isvg src={HHLogo} />
                            </div>
                            {folowersHH.map((item, _id) => {
                                return (
                                    <Folowers last key={_id} svg={item.svg} numbers={item.numbers} text={item.text} border={item.border} color={item.color} />
                                )
                            })}

                            <img src={Mockup} alt="MM Mockup" />

                        </div>
                    </div>
                </div>

                <div className="gradient-wrapper">
                    <div className="">
                        <h2>Over 3 million users see our posts and products
                            every month.</h2>
                        <button>create your <span>free</span> account</button>
                    </div>
                </div>
                <OneClick
                    background={SecondAccountBGC}
                    title="Our new tecnology links your property directly with our service."
                    subtitle="We only need your website address"
                />
                <div className="customer-wrapper">
                    <Title text="Get to know your new customers in our social media channels" />
                    <div className="customer">
                        <img src={MockupCustomer} alt="Mockup" />
                        <div>
                            <div className="item">
                                <Isvg src={Lead} />
                                <div>
                                    <div className="heading">Free Leads</div>
                                    <div className="text">Capture more leards and
                                        increase your sales.</div>
                                </div>
                            </div>
                            <div className="item">
                                <Isvg src={Unlimited} />
                                <div>
                                    <div className="heading">Unlimited properties</div>
                                    <div className="text">Add as much properties you
                                        want to get reach</div>
                                </div>
                            </div>
                            <div className="item">
                                <Isvg src={Cancel} />
                                <div>
                                    <div className="heading">Cancel at anytime</div>
                                    <div className="text">You have the freedom to cancel
                                        your service.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        </div>
    )
}

export default PageAccounts