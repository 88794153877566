export const RadioButton = ({ name, onChange, label, value }) => {
    return (
        <div className="checkbox">
            <label className="container">
                <input type="radio" name={name} checked={value === name} onChange={onChange} />
                <span className="checkmark"></span>
            </label>
            <label>{label}</label>
        </div>
    )
}