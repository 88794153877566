import React, { useCallback, useState } from "react";
import Isvg from 'react-inlinesvg';
import Lock from '../../../images/svg/lock-bright.svg';
import { useToken } from '../../../hooks/auth/useToken';
import { HOST_NAME } from '../../../config';
import axios from 'axios';

export const CompanyName = ({ current, address, premium }) => {
  const [companyName, setCompanyName] = useState(current.name);
  const [companyDesc, setCompanyDesc] = useState(current.description);
  const [addres, setAddres] = useState(address);
  const [loading, setLoading] = useState({ name: false, desc: false, addr: false });
  const [token] = useToken();



  const handleCompanyName = useCallback((e) => {
    setCompanyName(e.target.value);
  }, [])

  const handleCompanyDescription = useCallback((e) => {
    setCompanyDesc(e.target.value);
  }, [])

  const handleEnableArea = useCallback(() => {
    alert("We need to handle how to make user from free to premium");
  }, [])

  const handleAddresForm = useCallback((e) => {
    if (e.target.name === 'address1' || e.target.name === 'address2') {
      const addresses = [...addres.addresses];
      addresses[e.target.name === 'address1' ? 0 : 1] = e.target.value;
      setAddres({ ...addres, addresses });
    } else
      setAddres({ ...addres, [e.target.name]: e.target.value })
  }, [addres])

  const _performUpdate = useCallback((data, type) => {
    setLoading({ ...loading, [type]: true })

    const config = {
      method: 'post',
      url: `${HOST_NAME}user/update`,
      headers: {
        'Authorization': 'Bearer ' + token,
      },
      data: data
    };
    axios(config)
      .then(res => console.log("Updated successfully"))
      .catch(err => console.log(err))
      .finally(() => setLoading({ ...loading, [type]: false }));
  }, [loading, token, setLoading]);

  const updateCompanyName = useCallback(() => {
    const payload = {
      type: "company",
      name: companyName,
    }
    _performUpdate(payload, "name");
  }, [_performUpdate, companyName]);

  const updateCompanyDescription = useCallback(() => {
    const payload = {
      type: "company",
      description: companyDesc,
    }
    _performUpdate(payload, "desc");
  }, [_performUpdate, companyDesc]);

  const updateCompanyAddress = useCallback(() => {
    const payload = {
      type: "address",
      ...addres
    }
    _performUpdate(payload, "addr");
  }, [_performUpdate, addres]);

  return (
    <div className="account-settings-wrapper">
      <div className="content company-name">
        <h4>Company name</h4>
        <div className="edit-company-name">
          <div className="input">
            <label>Company name</label>
            <input type="text" onChange={handleCompanyName} value={companyName} />
            <button onClick={updateCompanyName} disabled={loading.name}>{loading.name ? "SAVING..." : "SAVE"}</button>
          </div>
        </div>
        <div className="company-description">
          {!premium &&
            <div className="label">
              <h5>Description <Isvg src={Lock} /></h5>
              <div className="upgrade">
                <p>Description is not available in your account</p>
                <button className="black-button" onClick={handleEnableArea}>Upgrade</button>
              </div>
            </div>
          }
          <div className="input">
            <label>Description</label>
            <textarea disabled={!premium} onChange={handleCompanyDescription} value={companyDesc}></textarea>
            <button disabled={!premium || loading.desc} onClick={updateCompanyDescription}>{loading.desc ? "SAVING..." : "SAVE"}</button>
          </div>
        </div>

        <div className="company-address">
          {!premium &&
            <div className="label">
              <h4>Address</h4>
              <div className="upgrade">
                <p>Address and zip is not available in your account</p>
                <button className="black-button" onClick={handleEnableArea}>Upgrade</button>
              </div>
            </div>
          }
          <div className="input">
            <label>Address 1</label>
            <input disabled={!premium} type="text" placeholder="Address 1" name="address1" onChange={handleAddresForm} value={addres.addresses[0]} />
          </div>

          <div className="input">
            <label>Address 2</label>
            <input disabled={!premium} type="text" placeholder="Address 2" name="address2" onChange={handleAddresForm} value={addres.addresses[1]} />
          </div>

          <div className="input">
            <label>Country</label>
            <input type="text" name="country" onChange={handleAddresForm} value={addres.country} />
          </div>

          <div className="input">
            <label>State</label>
            <input type="text" name="state" onChange={handleAddresForm} value={addres.state} />
          </div>
          <div className="input">
            <label>City</label>
            <input type="text" name="city" onChange={handleAddresForm} value={addres.city} />
          </div>
          <div className="input">
            <label>Zip/postal code</label>
            <input disabled={!premium} type="text" placeholder="Zip" name="zip_code" onChange={handleAddresForm} value={addres.zip_code} />
          </div>
          <button disabled={!premium || loading.addr} onClick={updateCompanyAddress}>{loading.addr ? "SAVING..." : "SAVE"}</button>
        </div>
      </div>
    </div>
  )
}