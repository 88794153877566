import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { BillingModal } from '../Modal';

const BillingModalContext = createContext();

export const BillingModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const value = useMemo(
    () => ({
      isOpen,
      openModal,
      closeModal,
    }),
    [closeModal, isOpen, openModal]
  );

  return (
    <BillingModalContext.Provider value={value}>
      {children}
      {isOpen && (
        <div className="modal-content-wrapper">
          <div className="modal-wrapper">
            <BillingModal />
          </div>
        </div>
      )}
    </BillingModalContext.Provider>
  );
};

export const useBillingModal = () => useContext(BillingModalContext);
