import { useMemo } from 'react';

export const usePropertyTypeConfig = (handlePropertyType) =>
  useMemo(
    () => [
      { name: 'House', onChange: handlePropertyType, label: 'House' },
      {
        name: 'Multi-Family',
        onChange: handlePropertyType,
        label: 'Multi-Family',
      },
      { name: 'Condo', onChange: handlePropertyType, label: 'Condo' },
      { name: 'Townhouse', onChange: handlePropertyType, label: 'Townhouse' },
      { name: 'Villa', onChange: handlePropertyType, label: 'Villa' },
      { name: 'Farmhouse', onChange: handlePropertyType, label: 'Farmhouse' },
      {
        name: 'Sea-side condo',
        onChange: handlePropertyType,
        label: 'Sea-side condo',
      },
    ],
    [handlePropertyType]
  );

export const useDetailsConfig = (onChangeDetail) =>
  useMemo(
    () => [
      {
        title: 'General details',
        placeholder:
          'Recently renovated, remodeled, spacious, duplex, modern architecture......',
        name: 'detailGeneral',
        onChange: onChangeDetail,
      },
      {
        title: 'Kitchen details',
        placeholder:
          'Quartz counters, gas range, stainless steel appliances, kitchen island...',
        name: 'detailKitchen',
        onChange: onChangeDetail,
      },
      {
        title: 'Bathroom details',
        placeholder: 'Dual vanity, sauna, guest bath on main floor...',
        name: 'detailBathroom',
        onChange: onChangeDetail,
      },
      {
        title: 'Room details',
        placeholder:
          'Fireplace in living room, freshly painted, hardwood floors',
        name: 'detailRoom',
        onChange: onChangeDetail,
      },
      {
        title: 'Outdoor details',
        placeholder: 'Garden patio, balcony, private pool.....',
        name: 'detailOutdoor',
        onChange: onChangeDetail,
      },
      {
        title: 'Miscellaneous details',
        placeholder:
          'Plumbing recently upgraded, 24 hour security, gated community, shared gym...',
        name: 'detailMiscellaneous',
        onChange: onChangeDetail,
      },
    ],
    [onChangeDetail]
  );

export const useMeasurementConfig = (onChangeSelect) =>
  useMemo(
    () => [
      {
        name: 'measurementMunitLivingArea',
        placeholder: 'Living area',
        textInputName: 'livingArea',
        onChange: onChangeSelect,
        options: [
          {
            value: 'sqm (m²)',
            text: 'sqm (m²)',
            placeholder: 'Number of Square meter',
          },
          {
            value: 'Sq feet',
            text: 'Sq feet',
            placeholder: 'Number of Square feet',
          },
        ],
      },
      {
        name: 'measurementMunitPropertyArea',
        placeholder: 'Property area',
        textInputName: 'propertyArea',
        onChange: onChangeSelect,
        options: [
          {
            value: 'sqm (m²)',
            text: 'sqm (m²)',
            placeholder: 'Number of Square meter',
          }, 
          {
            value: 'Sq feet',
            text: 'Sq feet',
            placeholder: 'Number of Square feet',
          },
        ],
      },
    ],
    [onChangeSelect]
  );
