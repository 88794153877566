import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import Isvg from 'react-inlinesvg';
import { ListingDashboard } from '../components/listing-dashboard';
import { Modal } from '../components/modals';
import { Select } from '../components/select';
import { HOST_NAME } from '../config';
import { DefaultLayout } from '../containers/DefaultLayout';
import Calendar from '../images/svg/calendar.svg';
import Search from '../images/svg/search.svg';


const select = [
  {
    _id: 0,
    text: "Show all listings",
    value: "Show all listings"
  },
  {
    _id: 1,
    text: "Apartment",
    value: "Apartment"
  },
  {
    _id: 2,
    text: "House",
    value: "House"
  },
  {
    _id: 3,
    text: "Villa",
    value: "Villa"
  },
  {
    _id: 4,
    text: "Finca",
    value: "Finca"
  },
  {
    _id: 5,
    text: "Plot",
    value: "Plot"
  },
  {
    _id: 6,
    text: "Commercial",
    value: "Commercial"
  }
]

const statistic = [
  {
    _id: 1,
    text: "Statistics for last 1 day"
  },
  {
    _id: 2,
    text: "Statistics for last 7 days"
  },
  {
    _id: 3,
    text: "Statistics for last 30 days"
  },
  {
    _id: 4,
    text: "Statistics for last 1 year"
  },
]

export const DashboardListing = () => {
  const [listingData, setListingData] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [listing, setListing] = useState("Show all listings");
  const [searchQuery, setSearchQuery] = useState("");
  const [_, setStatisticSelect] = useState();
  const [loading, setLoading] = useState(false);

  // load properties
  useEffect(() => {
    setLoading(true);
    axios.get(`${HOST_NAME}properties`)
      .then(res => {
        const mappedData = res.data.map(item => {
          return {
            _id: item.slug,
            title: item.details.title,
            location: item.details.loc_city,
            agent: item.details.partner,
            pageView: item.stat.views || 0,
            leads: item.stat.leads || 0,
            score: item.stat.score || 0,
            price: `${item.details.price_unit} ${item.details.price_amount}`,
            is_enabled: item.details.is_enabled,
            thumbnail: item.thumbnail[0],
            category: item.details.type.toLowerCase()
          }
        });
        setAllProperties(mappedData);
        console.log(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  // filter
  useEffect(() => {
    let filteredData = allProperties;
    if (listing !== "Show all listings") filteredData = allProperties.filter(property => property.category === listing.toLowerCase());
    if (searchQuery.length > 0) {
      const qLower = searchQuery.toLowerCase();
      filteredData = filteredData.filter(property => {
        return property.title.toLowerCase().includes(qLower) || property.location.toLowerCase().includes(qLower);
      });
    }
    setListingData(filteredData);
  }, [listing, allProperties, searchQuery]);

  const handleOpenModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal])

  const selectListing = useCallback((value) => {
    setListing(value[value.name]);
  }, [])

  const selectStatistic = useCallback((value) => {
    setStatisticSelect(value[value.name]);
  }, [])

  const setPropertyStatus = useCallback((e) => {
    // update server
    setLoading(true);
    const propertyIndex = listingData.findIndex(item => item._id === e.target.name);
    axios.post(`${HOST_NAME}properties/status`, { slug: e.target.name, status: e.target.checked })
      .then(res => {
        if (res.status === 200) {
          // Update the gui
          setListingData((prevState) => ([
            // eslint-disable-next-line eqeqeq
            ...prevState.slice(0, prevState.findIndex((l) => l._id == e.target.name)),
            // eslint-disable-next-line eqeqeq
            { ...prevState[prevState.findIndex((l) => l._id == e.target.name)], is_enabled: res.data.status },
            // eslint-disable-next-line eqeqeq
            ...prevState.slice(prevState.findIndex((l) => l._id == e.target.name) + 1)
          ]))
        }
      }).catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const handleDelete = useCallback((slug) => {
    setLoading(true);
    axios.delete(`${HOST_NAME}properties/${slug}`)
      .then(res => {
        if (res.status === 200) {
          setLoading(false)
          setListingData(listingData.filter(data => data._id !== slug));
        }
      })
      .catch(err => console.log(err));
  }, [listingData, setListingData]);

  return (
    <DefaultLayout isPrivate={true} loading={loading}>
      <Modal handleOpenModal={handleOpenModal} openModal={openModal} />
      <div className="dashboard-listing-wrapper">
        <div className="dashboard-listing-content">
          <h1>Listing</h1>
          <div className="content">
            <button>All listings : {listingData.length}</button>
            <div className="filters">
              <div className="search">
                <Isvg src={Search} />
                <input type="text" placeholder="Listing ID, name, location or agent" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
              </div>
              <div className="wrapper-select">
                <Select data={select} name="listing" placeholder="Show all listings" selectValueHandler={selectListing} />
                <Select data={statistic} name="statistic" svg={Calendar} placeholder="Statistics for last 30 days" selectValueHandler={selectStatistic} />
              </div>
                <button className="create-new" onClick={handleOpenModal}>Create new Listing</button>
            </div>
            {listingData.map((item, _idx) => {
              return (
                <ListingDashboard key={_idx} data={item} handleToggleButtons={setPropertyStatus} loading={loading} handleDelete={handleDelete} />
              )
            })}
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}