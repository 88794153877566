import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from 'firebase/auth';

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyB_Qi3bqNvrIBSywoToARgJ4xnXbIm5Fd4",
    authDomain: "hellohere-2021.firebaseapp.com",
    projectId: "hellohere-2021",
    messagingSenderId: "652470386925",
    appId: "1:652470386925:web:af2fc9aad3831621960ce4",
    measurementId: "G-WQBTRPM07B"
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// providers
export const googleProvider = new GoogleAuthProvider();
// need a facebook app for faebook login
// need a apple app for faebook login.
